import React, { useEffect, useState } from 'react';
import { Button, Form, Card, Table, Alert, Modal } from 'react-bootstrap';
import { parseISO, addDays, format } from 'date-fns';
import InputText from '../components/input_text';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';
import * as XLSX from 'xlsx';

const Reportes_Card = () => {

    const [grupos, setGrupos] = useState([]);
    const [tipoFiltro, setTipoFiltro] = useState("Grupo"); // Estado para tipo de filtro
    const [grupoSeleccionado, setGrupoSeleccionado] = useState([]);
    const [grupoSeleccionadoCompleto, setGrupoSeleccionadoCompleto] = useState([]);
    const [selectedEncuestas, setSelectedEncuestas] = useState([]);
    const [selectedEncuestasCompleto, setSelectedEncuestasCompleto] = useState([]);
    const [encuestasPromedios, setEncuestasPromedios] = useState([]);
    const [error, setError] = useState('');

    const [maestroEncuesta, setMaestroEncuesta] = useState(null);
    const [estadoEncuesta, setEstadoEncuesta] = useState(null);
    const [periodoEncuesta, setPeriodoEncuesta] = useState(null);
    const [areaEncuesta, setAreaEncuesta] = useState(null);
    const [ofertaEncuesta, setOfertaEncuesta] = useState(null);
    const [moduloEncuesta, setModuloEncuesta] = useState(null);

    const [encuestasData, setEncuestasData] = useState({
        Dominio: '',
        Horario: '',
        Trato: '',
        Dudas: '',
        Evaluacion: '',
        Objetivos: '',
        Practicas: '',
        Organizacion: '',
        Curso: '',
        Instalaciones: '',
        ServiciosCliente: '',
        Promedio: '',
        Contestados: ''
    });

    const handleFiltroChange = (e) => {
        setTipoFiltro(e.target.value);
        setGrupoSeleccionado([]);
        setGrupoSeleccionadoCompleto([]);
        setSelectedEncuestas([]);
        setSelectedEncuestasCompleto([]);
        setMaestroEncuesta(null);
        setEstadoEncuesta(null);
        setPeriodoEncuesta(null);
        setAreaEncuesta(null);
        setOfertaEncuesta(null);
        setModuloEncuesta(null);
    };

    useEffect(() => {
        if (tipoFiltro === "Grupo") {
            axiosInstance.get('/ListaGrupos')
                .then(response => {
                    setGrupos(response.data);
                })
                .catch(error => {
                    console.error('Error fetching grupos:', error);
                });
        } else if (tipoFiltro === "Encuesta") {
            axiosInstance.get('/ListaEncuestas')
                .then(response => {
                    setSelectedEncuestas(response.data);
                    setSelectedEncuestasCompleto(response.data);
                })
                .catch(error => {
                    console.error('Error fetching encuestas:', error);
                });
            axiosInstance.get('/ListaPromedios')
                .then(response => {
                    setEncuestasPromedios(response.data);
                })
                .catch(error => {
                    console.error('Error fetching promedios:', error);
                });
        }
    }, [tipoFiltro]);

    const handlePeriodoChange = (e) => {
        const periodo = parseInt(e.target.value);
        const periodoFiltrado = grupos.filter(g => g.Periodo === periodo);
        setGrupoSeleccionado(periodoFiltrado);
        setGrupoSeleccionadoCompleto(periodoFiltrado);
    };

    const handleGrupoChange = (e) => {
        const salonID = parseInt(e.target.value);
        const gruposFiltrados = grupoSeleccionado.filter(g => g.SalonID === salonID);
        setGrupoSeleccionado(gruposFiltrados);
    };

    const handleMaestroChange = (e) => {
        const maestroID = parseInt(e.target.value);
        const maestroFiltrado = grupoSeleccionado.filter(g => g.MaestroID === maestroID);
        setGrupoSeleccionado(maestroFiltrado);
    };

    const handleAreaChange = (e) => {
        const areaID = parseInt(e.target.value);
        const areaFiltrada = grupoSeleccionado.filter(g => g.AreaID === areaID);
        setGrupoSeleccionado(areaFiltrada);
    };

    const handleOfertaChange = (e) => {
        const ofertaID = parseInt(e.target.value);
        const ofertaFiltrada = grupoSeleccionado.filter(g => g.OfertaID === ofertaID);
        setGrupoSeleccionado(ofertaFiltrada);
    };

    const handleModuloChange = (e) => {
        const moduloID = parseInt(e.target.value);
        const moduloFiltrado = grupoSeleccionado.filter(g => g.ModuloID === moduloID);
        setGrupoSeleccionado(moduloFiltrado);
    };

    const handleFechaChange = (e) => {
        const fechaFil = formatDate(e.target.value);
        const fechaFiltrado = grupoSeleccionadoCompleto.filter(g => formatDate(g.Fecha_Inicial) === fechaFil);
        setGrupoSeleccionado(fechaFiltrado);
    };

    const today = new Date();

    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) {
            return "Fecha inválida";
        }
        // Si el valor es una fecha válida, devolver un formato consistente (YYYY-MM-DD)
        const d = new Date(date);
        return d.toISOString().split('T')[0];  // Formato 'YYYY-MM-DD'
    };

    const handleFiltroEncuestaChange = (e) => {
        const { name, value } = e.target;

        if (name === "maestroEncuesta") {
            setMaestroEncuesta(value);
        } else if (name === "estadoEncuesta") {
            setEstadoEncuesta(value);
        } else if (name === "periodoEncuesta") {
            setPeriodoEncuesta(value);
        } else if (name === "areaEncuesta") {
            setAreaEncuesta(value);
        } else if (name === "ofertaEncuesta") {
            setOfertaEncuesta(value);
        } else if (name === "moduloEncuesta") {
            setModuloEncuesta(value);
        }
    };

    // Aplicamos el filtrado cuando cambia maestroEncuesta o estadoEncuesta
    useEffect(() => {
        let filtrado = selectedEncuestasCompleto;

        if (maestroEncuesta) {
            filtrado = filtrado.filter(g => g.NombreMaestro === maestroEncuesta);
        }

        if (estadoEncuesta) {
            const today = format(new Date(), 'yyyy-MM-dd');
            filtrado = filtrado.filter(grupo => {
                const fechaInicio = format(parseISO(grupo.FechaInicio), 'yyyy-MM-dd');
                return estadoEncuesta === "Abierto" ? fechaInicio >= today : fechaInicio < today;
            });
        }

        if (periodoEncuesta) {
            filtrado = filtrado.filter(gr => gr.Periodo === parseInt(periodoEncuesta));
        }

        if (areaEncuesta) {
            filtrado = filtrado.filter(gr => gr.ID_Area === parseInt(areaEncuesta));
        }

        if (ofertaEncuesta) {
            filtrado = filtrado.filter(gr => gr.ID_Oferta === parseInt(ofertaEncuesta));
        }

        if (moduloEncuesta) {
            filtrado = filtrado.filter(gr => gr.ID_Modulo === parseInt(moduloEncuesta));
        }

        console.log(ofertaEncuesta, moduloEncuesta);

        setSelectedEncuestas(filtrado);
    }, [moduloEncuesta, ofertaEncuesta, areaEncuesta, maestroEncuesta, estadoEncuesta, periodoEncuesta, selectedEncuestasCompleto]);

    const formatHorarioCurso = (horario) => {
        // Asegúrate de que el horario no esté vacío o sea nulo
        if (!horario) return 'Horario no disponible';

        // Si el formato es como "14:00:00 - 17:00:00"
        const [inicio, fin] = horario.split(' - '); // Separar inicio y fin
        const formatoInicio = inicio.substring(0, 5); // Extraer "HH:mm"
        const formatoFin = fin.substring(0, 5); // Extraer "HH:mm"

        return `${formatoInicio} - ${formatoFin}`; // Retornar el formato deseado
    };

    const [show, setShow] = useState(false);
    const [showGrupos, setShowGrupos] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowGrupos, setSelectedRowGrupos] = useState(null);
    const handleClose = () => setShow(false);
    const handleCloseGrupos = () => setShowGrupos(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        getInformacionEncuesta(row.ID);
        setShow(true);
    };

    const handleShowGrupos = (row) => {
        setSelectedRowGrupos(row);
        setShowGrupos(true);
    };

    const getInformacionEncuesta = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        if (promedio) {
            setEncuestasData({
                Dominio: promedio.Dominio || '',
                Horario: promedio.Horario || '',
                Trato: promedio.Trato || '',
                Dudas: promedio.Dudas || '',
                Evaluacion: promedio.Evaluacion || '',
                Objetivos: promedio.Objetivos || '',
                Practicas: promedio.Practicas || '',
                Organizacion: promedio.Organizacion || '',
                Curso: promedio.Curso || '',
                Instalaciones: promedio.Instalaciones || '',
                ServiciosCliente: promedio.ServicioCliente || '',
                Promedio: promedio.Promedio || '',
                Contestados: promedio.Contestados || ''
            });
        }
    };

    const getContestadosForGrupo = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        return promedio ? promedio.Contestados : 'N/A'; // Si no hay promedio, muestra 'N/A'
    };

    const getPromedioForGrupo = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        return promedio ? promedio.Promedio : 'N/A'; // Si no hay promedio, muestra 'N/A'
    };

    const calcularCantidadClases = (fechaInicio, fechaFin, frecuencia) => {
        const diasFrecuencia = frecuencia.split(',').map(dia => {
            switch (dia.trim()) {
                case 'Lunes': return 1;
                case 'Martes': return 2;
                case 'Miércoles': return 3;
                case 'Jueves': return 4;
                case 'Viernes': return 5;
                case 'Sábado': return 6;
                case 'Domingo': return 0;
                default: return null;
            }
        }).filter(dia => dia !== null);

        let cantidadClases = 0;
        let fechaActual = parseISO(fechaInicio);
        const fechaFinObj = parseISO(fechaFin);

        while (!diasFrecuencia.includes(fechaActual.getDay()) && fechaActual <= fechaFinObj) {
            fechaActual = addDays(fechaActual, 1);
        }

        while (fechaActual <= fechaFinObj) {
            if (diasFrecuencia.includes(fechaActual.getDay())) {
                cantidadClases++;
            }
            fechaActual = addDays(fechaActual, 1);
        }
        return cantidadClases;
    };

    // Filtrar ofertas y módulos según el valor seleccionado en área
    const ofertasFiltradas = selectedEncuestasCompleto.filter(grupo => grupo.ID_Area === parseInt(areaEncuesta));
    const modulosFiltrados = selectedEncuestasCompleto.filter(grupo => grupo.ID_Oferta === parseInt(ofertaEncuesta));

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet([encuestasData]); // Convierte el objeto de datos a una hoja de trabajo
        const wb = XLSX.utils.book_new(); // Crea un nuevo libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, "Encuesta"); // Añade la hoja de trabajo al libro

        // Descarga el archivo Excel con el nombre 'Encuesta.xlsx'
        XLSX.writeFile(wb, 'Encuesta.xlsx');
    };


    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                {error && <Alert variant="danger">{error}</Alert>}
                <Card className="mb-3">
                    <Card.Body>
                        <h1>Reportes</h1>
                        <p className="card-text">Selecciona tipo de filtro y detalles</p>

                        {/* Select para elegir entre filtro por grupo o encuesta */}
                        <Form.Select
                            name="tipoFiltro"
                            className="mb-3"
                            onChange={(e) => {
                                handleFiltroChange(e);
                            }
                            }
                        >
                            <option value="Grupo">Filtrar por Grupo</option>
                            <option value="Encuesta">Filtrar por Encuesta</option>
                        </Form.Select>

                        {tipoFiltro === "Grupo" ? (
                            <>
                                <Form.Select
                                    name="periodoGrupoB"
                                    className="mb-3"
                                    onChange={handlePeriodoChange}
                                >
                                    <option value="">Seleccione un periodo</option>
                                    {Array.from(new Set(grupos.map(periodo => periodo.Periodo)))
                                        .map(uniquePeriodo => (
                                            <option key={uniquePeriodo} value={uniquePeriodo}>
                                                {uniquePeriodo}
                                            </option>
                                        ))}
                                </Form.Select>

                                <Form.Select
                                    name="grupo"
                                    className="mb-3"
                                    onChange={handleGrupoChange}
                                >
                                    <option value="">Seleccione un salon</option>
                                    {grupoSeleccionado
                                        .map(grupo => grupo.Numero_Salon)  // Extraer solo los números de salón
                                        .filter((numeroSalon, index, self) => self.indexOf(numeroSalon) === index) // Filtrar duplicados
                                        .map((numeroSalon, index) => {
                                            const salon = grupoSeleccionado.find(grupo => grupo.Numero_Salon === numeroSalon); // Encontrar el grupo correspondiente
                                            return (
                                                <option key={`${salon.SalonID}-${index}`} value={salon.SalonID}>
                                                    {numeroSalon}
                                                </option>
                                            );
                                        })}
                                </Form.Select>

                                <Form.Select
                                    name="maestro"
                                    className="mb-3"
                                    onChange={handleMaestroChange}
                                >
                                    <option value="">Seleccione un maestro</option>
                                    {grupoSeleccionado.map(grupo => (
                                        <option key={grupo.MaestroID} value={grupo.MaestroID}>{grupo.Maestro}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="area"
                                    className="mb-3"
                                    onChange={handleAreaChange}
                                >
                                    <option value="">Seleccione una area</option>
                                    {grupoSeleccionado.map(grupo => (
                                        <option key={grupo.AreaID} value={grupo.AreaID}>{grupo.Area}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="oferta"
                                    className="mb-3"
                                    onChange={handleOfertaChange}
                                >
                                    <option value="">Seleccione una oferta</option>
                                    {grupoSeleccionado.map(grupo => (
                                        <option key={grupo.OfertaID} value={grupo.OfertaID}>{grupo.Oferta}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="modulo"
                                    className="mb-3"
                                    onChange={handleModuloChange}
                                >
                                    <option value="">Seleccione un modulo</option>
                                    {grupoSeleccionado.map(grupo => (
                                        <option key={grupo.ModuloID} value={grupo.ModuloID}>{grupo.Modulo}</option>
                                    ))}
                                </Form.Select>

                                <InputText
                                    type="date"
                                    name="fecha"
                                    onChange={handleFechaChange}
                                />

                                <Table>
                                    <thead>
                                        <tr>
                                            <td>Area</td>
                                            <td>Instructor</td>
                                            <td>Materia</td>
                                            <td>Oferta educativa</td>
                                            <td>Modalidad</td>
                                            <td>Salon</td>
                                            <td>Inicio de curso</td>
                                            <td>Frecuencia de clases</td>
                                            <td>Cantidad de clases</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grupoSeleccionado.slice().reverse().map((seleccion, index) => (
                                            <tr key={index} onClick={() => handleShowGrupos(seleccion)}>
                                                <td>{seleccion.Area}</td>
                                                <td>{seleccion.Maestro}</td>
                                                <td>{seleccion.Modulo}</td>
                                                <td>{seleccion.Oferta}</td>
                                                <td>{seleccion.Modalidad}</td>
                                                <td>{seleccion.Numero_Salon}</td>
                                                <td>{formatDate(seleccion.Fecha_Inicial)}</td>
                                                <td>{seleccion.Frecuencia}</td>
                                                <td>{calcularCantidadClases(seleccion.Fecha_Inicial, seleccion.Fecha_Final, seleccion.Frecuencia)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <>
                                <Form.Select
                                    name="estadoEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Estado de la encuesta</option>
                                    <option value="Abierto">Abierto</option>
                                    <option value="Cerrado">Cerrado</option>
                                </Form.Select>
                                <Form.Select
                                    name="periodoEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Seleccione un periodo</option>
                                    {selectedEncuestasCompleto.map(encuesta => (
                                        <option key={encuesta.Periodo} value={encuesta.Periodo}>{encuesta.Periodo}</option>
                                    ))}
                                </Form.Select>
                                <Form.Select
                                    name="maestroEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Seleccione un maestro</option>
                                    {selectedEncuestasCompleto.map(encuesta => (
                                        <option key={encuesta.NombreMaestro} value={encuesta.NombreMaestro}>{encuesta.NombreMaestro}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="areaEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Seleccione una area</option>
                                    {selectedEncuestasCompleto.map(grupo => (
                                        <option key={grupo.ID_Area} value={grupo.ID_Area}>{grupo.NombreArea}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="ofertaEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Seleccione una oferta</option>
                                    {ofertasFiltradas.map(grupo => (
                                        <option key={grupo.ID_Oferta} value={grupo.ID_Oferta}>{grupo.NombreOferta}</option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    name="moduloEncuesta"
                                    className="mb-3"
                                    onChange={handleFiltroEncuestaChange}
                                >
                                    <option value="">Seleccione un modulo</option>
                                    {modulosFiltrados.map(grupo => (
                                        <option key={grupo.ID_Modulo} value={grupo.ID_Modulo}>{grupo.NombreModulo}</option>
                                    ))}
                                </Form.Select>

                                <Table>
                                    <thead>
                                        <tr>
                                            <td>Fecha de la encuesta</td>
                                            <td>Codigo</td>
                                            <td>Area</td>
                                            <td>Oferta</td>
                                            <td>Modulo</td>
                                            <td>Maestro</td>
                                            <td>Aula</td>
                                            <td>Dia</td>
                                            <td>Horario Curso</td>
                                            <td>Periodo</td>
                                            <td>Alumnos</td>
                                            <td>Contestados</td>
                                            <td>Promedio</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedEncuestas.slice().reverse().map((grupo, index) => (
                                            <tr key={index} onClick={() => handleShow(grupo)}>
                                                <td>{formatDate(grupo.FechaInicio)}</td>
                                                <td>{grupo.Codigo}</td>
                                                <td>{grupo.NombreArea}</td>
                                                <td>{grupo.NombreOferta}</td>
                                                <td>{grupo.NombreModulo}</td>
                                                <td>{grupo.NombreMaestro}</td>
                                                <td>{grupo.NombreSalon}</td>
                                                <td>{grupo.Frecuencia}</td>
                                                <td>{formatHorarioCurso(grupo.HorarioCurso)}</td>
                                                <td>{grupo.Periodo}</td>
                                                <td>{grupo.Cantidad}</td>
                                                <td>{getContestadosForGrupo(grupo.ID)}</td>
                                                <td>{getPromedioForGrupo(grupo.ID)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informacion de la encuesta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>Inicio de la encuesta:</strong> {formatDate(selectedRow.FechaInicio) || 'No disponible'}</p>
                                <p><strong>Maestro:</strong> {selectedRow.NombreMaestro || 'No disponible'}</p>
                                <p><strong>Dominio:</strong> {encuestasData.Dominio || 'No disponible'}</p>
                                <p><strong>Horario:</strong> {encuestasData.Horario || 'No disponible'}</p>
                                <p><strong>Trato:</strong> {encuestasData.Trato || 'No disponible'}</p>
                                <p><strong>Dudas:</strong> {encuestasData.Dudas || 'No disponible'}</p>
                                <p><strong>Evaluacion:</strong> {encuestasData.Evaluacion || 'No disponible'}</p>
                                <p><strong>Objetivos:</strong> {encuestasData.Objetivos || 'No disponible'}</p>
                                <p><strong>Practicas:</strong> {encuestasData.Practicas || 'No disponible'}</p>
                                <p><strong>Organizacion:</strong> {encuestasData.Organizacion || 'No disponible'}</p>
                                <p><strong>Curso:</strong> {encuestasData.Curso || 'No disponible'}</p>
                                <p><strong>Instalaciones:</strong> {encuestasData.Instalaciones || 'No disponible'}</p>
                                <p><strong>Servicios Cliente:</strong> {encuestasData.ServiciosCliente || 'No disponible'}</p>
                                <p><strong>Promedio:</strong> {encuestasData.Promedio || 'No disponible'}</p>
                                <p><strong>Contestados:</strong> {encuestasData.Contestados || 'No disponible'}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={exportToExcel}>
                            Exportar
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showGrupos} onHide={handleCloseGrupos}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informacion del grupo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRowGrupos && (
                            <div>
                                <p><strong>Inicio del grupo:</strong> {formatDate(selectedRowGrupos.Fecha_Inicial) || 'No disponible'}</p>
                                <p><strong>Maestro:</strong> {selectedRowGrupos.Maestro || 'No disponible'}</p>
                                <p><strong>Area:</strong> {selectedRowGrupos.Area || 'No disponible'}</p>
                                <p><strong>Oferta:</strong> {selectedRowGrupos.Oferta || 'No disponible'}</p>
                                <p><strong>Modulo:</strong> {selectedRowGrupos.Modulo || 'No disponible'}</p>
                                <p><strong>Modalidad:</strong> {selectedRowGrupos.Modalidad || 'No disponible'}</p>
                                <p><strong>Frecuencia:</strong> {selectedRowGrupos.Frecuencia || 'No disponible'}</p>
                                <p><strong>Cantidad de Clases:</strong> {calcularCantidadClases(selectedRowGrupos.Fecha_Inicial, selectedRowGrupos.Fecha_Final, selectedRowGrupos.Frecuencia) || 'No disponible'}</p>
                                <p><strong>Salon:</strong> {selectedRowGrupos.Numero_Salon || 'No disponible'}</p>
                                <p><strong>Espacios en el Salon:</strong> {selectedRowGrupos.SalonEspacios || 'No disponible'}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseGrupos}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Reportes_Card;
