import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdAdmin_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [adminData, setAdminData] = useState({
        ID: '',
        username: '',
        password: '',
        IDrol: 1,
        exists: 1,
    });

    const handleCrearAdministradores = () => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.post('/actualizarUsuario', adminData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPageAdmin');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            axiosInstance.post('/crearUsuario', adminData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPageAdmin');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerEdicionUsuario?id=${searchParam}`)
                .then(response => {
                    const AdminArray = response.data;

                    if (Array.isArray(AdminArray) && AdminArray.length > 0) {
                        const admin = AdminArray[0]; // Acceder al primer objeto en el array

                        setAdminData({
                            ID: admin.ID,
                            IDrol: admin.ID_Rol,
                            username: admin.Nombre,
                            password: admin.Contraseña,
                            exists: admin.Existe,
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo admin:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Administrador</h5>
                        <p className="card-text">Ingresa el username del administrador</p>
                        <InputText
                            type="text"
                            name="username"
                            value={adminData.username}
                            onChange={(e) => setAdminData({ ...adminData, username: e.target.value })}
                        />
                        <p className="card-text">Ingresa la contraseña del administrador</p>
                        <InputText
                            type="text"
                            name="password"
                            value={adminData.password}
                            onChange={(e) => {
                                setAdminData({ ...adminData, password: e.target.value });
                                // Log al actualizar el password
                                console.log('Updated password:', e.target.value);
                            }}
                        />
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearAdministradores}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdAdmin_Card;
