import React, { useEffect, useState } from 'react';
import { Form, Card, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { parseISO, addDays, format } from 'date-fns';
import * as XLSX from 'xlsx';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const MinutaConsultor_Card = () => {
    const [alumnoData, setAlumnoData] = useState({
        ID: '',
        AlumnoID: '',
        GrupoID: '',
        asistenciaClase1: '',
        asistenciaClase2: '',
        asistenciaClase3: '',
        asistenciaClase4: '',
        practica1Clase1: '',
        practica2Clase1: '',
        practica3Clase1: '',
        practica4Clase1: '',
        practica5Clase1: '',
        practica1Clase2: '',
        practica2Clase2: '',
        practica3Clase2: '',
        practica4Clase2: '',
        practica5Clase2: '',
        practica1Clase3: '',
        practica2Clase3: '',
        practica3Clase3: '',
        practica4Clase3: '',
        practica5Clase3: '',
        practica1Clase4: '',
        practica2Clase4: '',
        practica3Clase4: '',
        practica4Clase4: '',
        practica5Clase4: '',
        Tarea1: '',
        Tarea2: '',
        Tarea3: '',
        Proyecto: '',
        Examen: '',
        Participacion: '',
        Comentarios: '',
        claseSeleccionada: '',
        CalificacionFinal: '',
    });

    const defaultAlumnoData = {
        ID: '',
        AlumnoID: '',
        GrupoID: '',
        asistenciaClase1: '',
        asistenciaClase2: '',
        asistenciaClase3: '',
        asistenciaClase4: '',
        practica1Clase1: '',
        practica2Clase1: '',
        practica3Clase1: '',
        practica4Clase1: '',
        practica5Clase1: '',
        practica1Clase2: '',
        practica2Clase2: '',
        practica3Clase2: '',
        practica4Clase2: '',
        practica5Clase2: '',
        practica1Clase3: '',
        practica2Clase3: '',
        practica3Clase3: '',
        practica4Clase3: '',
        practica5Clase3: '',
        practica1Clase4: '',
        practica2Clase4: '',
        practica3Clase4: '',
        practica4Clase4: '',
        practica5Clase4: '',
        Tarea1: '',
        Tarea2: '',
        Tarea3: '',
        Proyecto: '',
        Examen: '',
        Participacion: '',
        Comentarios: '',
        CalificacionFinal: '',
    };

    const [alumnos, setAlumnos] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [alumnoActual, setAlumnoActual] = useState("");
    const [mostrarCamposAlumno, setMostrarCamposAlumno] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        axiosInstance.get('/ListaAlumnoGrupos')
            .then(response => {
                setAlumnos(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);

    const handleGrupoChange = (e) => {
        const grupoId = e.target.value;
        if (grupoId === "") {
            setGrupoSeleccionado(null); // Limpiar la tabla si se selecciona la opción vacía
            setMostrarCamposAlumno(false);
            setAlumnoData(defaultAlumnoData);
        } else {
            const grupo = grupos.find(grupo => grupo.GrupoID === parseInt(grupoId));
            setGrupoSeleccionado(grupo); // Actualizar con los datos del grupo seleccionado
            setMostrarCamposAlumno(true);
            setAlumnoData(defaultAlumnoData);

            axiosInstance.get(`/obtenerInformacionAlumno?id=${alumnoActual}&grupoId=${grupoId}`)
                .then(response => {
                    const alumnoArray = response.data;
                    if (Array.isArray(alumnoArray) && alumnoArray.length > 0) {
                        const alumno = alumnoArray[0];
                        setAlumnoData({
                            ID: alumno.ID,
                            AlumnoID: alumno.IDAlumno,
                            GrupoID: alumno.IDGrupo,
                            asistenciaClase1: alumno.AsistenciaUno,
                            asistenciaClase2: alumno.AsistenciaDos,
                            asistenciaClase3: alumno.AsistenciaTres,
                            asistenciaClase4: alumno.AsistenciaCuatro,
                            practica1Clase1: alumno.Practica1,
                            practica2Clase1: alumno.Practica2,
                            practica3Clase1: alumno.Practica3,
                            practica4Clase1: alumno.Practica4,
                            practica5Clase1: alumno.Practica5,
                            practica1Clase2: alumno.Practica6,
                            practica2Clase2: alumno.Practica7,
                            practica3Clase2: alumno.Practica8,
                            practica4Clase2: alumno.Practica9,
                            practica5Clase2: alumno.Practica10,
                            practica1Clase3: alumno.Practica11,
                            practica2Clase3: alumno.Practica12,
                            practica3Clase3: alumno.Practica13,
                            practica4Clase3: alumno.Practica14,
                            practica5Clase3: alumno.Practica15,
                            practica1Clase4: alumno.Practica16,
                            practica2Clase4: alumno.Practica17,
                            practica3Clase4: alumno.Practica18,
                            practica4Clase4: alumno.Practica19,
                            practica5Clase4: alumno.Practica20,
                            Tarea1: alumno.Tarea1,
                            Tarea2: alumno.Tarea2,
                            Tarea3: alumno.Tarea3,
                            Proyecto: alumno.Proyecto,
                            Examen: alumno.Examen,
                            Participacion: alumno.Participacion,
                            Comentarios: alumno.Comentarios,
                            CalificacionFinal: alumno.CalificacionFinal,
                        });
                    } else {
                        setError('Por favor, asegúrate con el maestro que todos los campos de la minuta estén llenos.');
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo alumnos:', error);
                });
        }
    };

    const formatDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd/MM/yyyy') : 'N/A';
    };

    const calcularCantidadClases = (fechaInicio, fechaFin, frecuencia) => {
        const diasFrecuencia = frecuencia.split(',').map(dia => {
            switch (dia.trim()) {
                case 'Lunes': return 1;
                case 'Martes': return 2;
                case 'Miércoles': return 3;
                case 'Jueves': return 4;
                case 'Viernes': return 5;
                case 'Sábado': return 6;
                case 'Domingo': return 0;
                default: return null;
            }
        }).filter(dia => dia !== null);

        let cantidadClases = 0;
        let fechaActual = parseISO(fechaInicio);
        const fechaFinObj = parseISO(fechaFin);

        // Avanza al primer día que coincida con la frecuencia indicada
        while (!diasFrecuencia.includes(fechaActual.getDay()) && fechaActual <= fechaFinObj) {
            fechaActual = addDays(fechaActual, 1);
        }

        // Cuenta las clases en los días de frecuencia hasta la fecha final
        while (fechaActual <= fechaFinObj) {
            if (diasFrecuencia.includes(fechaActual.getDay())) {
                cantidadClases++;
            }
            fechaActual = addDays(fechaActual, 1); // Avanza un día a la vez
        }
        return cantidadClases;
    };

    const handleAlumnoChange = (e) => {
        const alumnoId = e.target.value;

        setAlumnoActual(alumnoId);
        setError('');
        setAlumnoData(defaultAlumnoData);
        setGrupoSeleccionado(null);
        setMostrarCamposAlumno(false);
        setGrupos([]);
        if (alumnoId !== "") { // Mostrar campos si se selecciona un alumno
            axiosInstance.get(`/ListaGruposAlumnos?id=${alumnoId}`)
                .then(response => {
                    setGrupos(response.data);
                })
                .catch(error => {
                    console.error('Error fetching maestros:', error);
                });
        }
    };

    const handleClaseChange = (e) => {
        const claseSeleccionada = e.target.value;
        setAlumnoData({
            ...alumnoData,
            claseSeleccionada: claseSeleccionada
        });
    };

    const handleCrearExcel = () => {
        // Define los datos que quieres incluir en el Excel.
        const datosExcel = [
            {
                "ID del Alumno": alumnoData.ID,
                "Calificación Final": alumnoData.CalificacionFinal,
                "Calificación Proyecto": alumnoData.Proyecto || "N/A",
                "Calificación Examen": alumnoData.Examen || "N/A",
                "Participación": alumnoData.Participacion || "N/A",
                "Comentarios": alumnoData.Comentarios || "",
                "Asistencia Clase 1": alumnoData.asistenciaClase1 || "N/A",
                "Asistencia Clase 2": alumnoData.asistenciaClase2 || "N/A",
                "Asistencia Clase 3": alumnoData.asistenciaClase3 || "N/A",
                "Asistencia Clase 4": alumnoData.asistenciaClase4 || "N/A",
                "Práctica 1 Clase 1": alumnoData.practica1Clase1 || "N/A",
                "Práctica 2 Clase 1": alumnoData.practica2Clase1 || "N/A",
                "Práctica 3 Clase 1": alumnoData.practica3Clase1 || "N/A",
                "Práctica 4 Clase 1": alumnoData.practica4Clase1 || "N/A",
                "Práctica 5 Clase 1": alumnoData.practica5Clase1 || "N/A",
                "Práctica 1 Clase 2": alumnoData.practica1Clase2 || "N/A",
                "Práctica 2 Clase 2": alumnoData.practica2Clase2 || "N/A",
                "Práctica 3 Clase 2": alumnoData.practica3Clase2 || "N/A",
                "Práctica 4 Clase 2": alumnoData.practica4Clase2 || "N/A",
                "Práctica 5 Clase 2": alumnoData.practica5Clase2 || "N/A",
                "Práctica 1 Clase 3": alumnoData.practica1Clase3 || "N/A",
                "Práctica 2 Clase 3": alumnoData.practica2Clase3 || "N/A",
                "Práctica 3 Clase 3": alumnoData.practica3Clase3 || "N/A",
                "Práctica 4 Clase 3": alumnoData.practica4Clase3 || "N/A",
                "Práctica 5 Clase 3": alumnoData.practica5Clase3 || "N/A",
                "Práctica 1 Clase 4": alumnoData.practica1Clase4 || "N/A",
                "Práctica 2 Clase 4": alumnoData.practica2Clase4 || "N/A",
                "Práctica 3 Clase 4": alumnoData.practica3Clase4 || "N/A",
                "Práctica 4 Clase 4": alumnoData.practica4Clase4 || "N/A",
                "Práctica 5 Clase 4": alumnoData.practica5Clase4 || "N/A",
                "Tarea 1": alumnoData.Tarea1 || "N/A",
                "Tarea 2": alumnoData.Tarea2 || "N/A",
                "Tarea 3": alumnoData.Tarea3 || "N/A",
            }
        ];

        // Crea una hoja de trabajo a partir de los datos.
        const hojaTrabajo = XLSX.utils.json_to_sheet(datosExcel);

        // Crea un libro de trabajo y agrega la hoja de trabajo.
        const libroTrabajo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(libroTrabajo, hojaTrabajo, "Calificaciones");

        // Genera el archivo Excel y lo descarga.
        XLSX.writeFile(libroTrabajo, `Calificaciones_Alumno_${alumnoData.ID}.xlsx`);
    };


    // Renderizar campos adicionales dependiendo del número de la clase seleccionada
    const renderCampoClaseSeleccionada = () => {
        if (!alumnoData.claseSeleccionada) {
            return null;
        }

        const claseNumero = parseInt(alumnoData.claseSeleccionada.replace('class', ''));
        const asistencia = alumnoData[`asistenciaClase${claseNumero}`];

        const getOptionsForPractica = () => {
            switch (asistencia) {
                case 'Asistencia':
                    return ['E', 'C', 'D', 'N'];
                case 'Retardo':
                    return ['C', 'D', 'N'];
                case 'Falta':
                    return ['C'];
                case 'Justificante':
                    return ['C', 'D', 'N'];
                default:
                    return [];
            }
        };

        const getOptionsForTarea = () => {
            switch (asistencia) {
                case 'Asistencia':
                    return ['E', 'C', 'D', 'N'];
                case 'Retardo':
                    return ['E', 'C', 'D', 'N'];
                case 'Falta':
                    return ['C', 'D', 'N'];
                case 'Justificante':
                    return ['C', 'D', 'N'];
                default:
                    return [];
            }
        };

        return (
            <div>
                <Form.Group className="mb-3">
                    <Form.Label>Asistencia para Clase {claseNumero}</Form.Label>
                    <Form.Select
                        name={`asistenciaClase${claseNumero}`}
                        className="mb-3"
                        value={alumnoData[`asistenciaClase${claseNumero}`] || ''}
                        onChange={(e) =>
                            setAlumnoData({
                                ...alumnoData,
                                [`asistenciaClase${claseNumero}`]: e.target.value
                            })
                        }
                        disabled
                    >
                        <option value="">Seleccione la asistencia para Clase {claseNumero}</option>
                        <option value="Asistencia">Asistencia</option>
                        <option value="Retardo">Retardo</option>
                        <option value="Falta">Falta</option>
                        <option value="Justificante">Justificante</option>
                    </Form.Select>
                </Form.Group>

                {[...Array(5)].map((_, i) => (
                    <Form.Group className="mb-3" key={`practica-${i}`}>
                        <Form.Label>Practica {i + 1} para Clase {claseNumero}</Form.Label>
                        <Form.Select
                            name={`practica${i + 1}Clase${claseNumero}`}
                            className="mb-3"
                            value={alumnoData[`practica${i + 1}Clase${claseNumero}`] || ''}
                            onChange={(e) =>
                                setAlumnoData({
                                    ...alumnoData,
                                    [`practica${i + 1}Clase${claseNumero}`]: e.target.value
                                })
                            }
                            disabled
                        >
                            <option value="">Seleccione la calificación de la practica {i + 1} para Clase {claseNumero}</option>
                            {getOptionsForPractica().map((optionValue) => (
                                <option key={optionValue} value={optionValue}>{optionValue}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                ))}


                {claseNumero <= 3 && (
                    <Form.Group className="mb-3">
                        <Form.Label>Tarea para Clase {claseNumero}</Form.Label>
                        <Form.Select
                            name={`Tarea${claseNumero}`}
                            className="mb-3"
                            value={alumnoData[`Tarea${claseNumero}`] || ''}
                            onChange={(e) =>
                                setAlumnoData({
                                    ...alumnoData,
                                    [`Tarea${claseNumero}`]: e.target.value
                                })
                            }
                            disabled
                        >
                            <option value="">Seleccione la calificación de la tarea para la clase {claseNumero}</option>
                            {getOptionsForTarea().map((optionValue) => (
                                <option key={optionValue} value={optionValue}>{optionValue}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
            </div>
        );
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                {error && <Alert variant="danger">{error}</Alert>}
                <Card className="mb-3">
                    <Card.Body>
                        <h1>Datos Generales</h1>

                        <p className="card-text">Selecciona el alumno</p>
                        <Form.Select
                            name="alumno"
                            className="mb-3"
                            onChange={(e) => {
                                setAlumnoData({
                                    ...alumnoData,
                                    AlumnoID: e.target.value
                                });
                                handleAlumnoChange(e);
                            }}
                        >
                            <option value="">Seleccione un alumno</option>
                            {alumnos
                                .filter((alumno, index, self) =>
                                    index === self.findIndex((a) => a.ID_Alumno === alumno.ID_Alumno)
                                )
                                .map(alumno => (
                                    <option key={alumno.ID_Alumno} value={alumno.ID_Alumno}>
                                        {alumno.alumnoNombre}{" "}{alumno.Matricula}
                                    </option>
                                ))}
                        </Form.Select>

                        <p className="card-text">Selecciona el grupo</p>
                        <Form.Select
                            name="grupo"
                            className="mb-3"
                            value={grupos.GrupoID}
                            onChange={(e) => {
                                setAlumnoData({
                                    ...alumnoData,
                                    GrupoID: e.target.value
                                });
                                handleGrupoChange(e);
                            }}
                        >
                            <option value="">Seleccione un grupo</option>
                            {grupos.map(grupo => (
                                <option key={grupo.GrupoID} value={grupo.GrupoID}>{grupo.Numero_Salon + '-' + grupo.Oferta}</option>
                            ))}
                        </Form.Select>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Area</td>
                                    <td>Instructor</td>
                                    <td>Materia</td>
                                    <td>Oferta educativa</td>
                                    <td>Modalidad</td>
                                    <td>Salon</td>
                                    <td>Inicio de curso</td>
                                    <td>Frecuencia de clases</td>
                                    <td>Cantidad de clases</td>
                                </tr>
                            </thead>
                            <tbody>
                                {grupoSeleccionado ? (
                                    <tr>
                                        <td>{grupoSeleccionado.Area}</td>
                                        <td>{grupoSeleccionado.Maestro}</td>
                                        <td>{grupoSeleccionado.Modulo}</td>
                                        <td>{grupoSeleccionado.Oferta}</td>
                                        <td>{grupoSeleccionado.Modalidad}</td>
                                        <td>{grupoSeleccionado.Numero_Salon}</td>
                                        <td>{formatDate(grupoSeleccionado.Fecha_Inicial)}</td>
                                        <td>{grupoSeleccionado.Frecuencia}</td>
                                        <td>{calcularCantidadClases(grupoSeleccionado.Fecha_Inicial, grupoSeleccionado.Fecha_Final, grupoSeleccionado.Frecuencia)}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="9" className="text-center">No hay datos para mostrar</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <h1>Llenado de minuta</h1>

                        {alumnoData.ID && alumnoData.CalificacionFinal && (
                            <Table striped bordered className="mt-3">
                                <thead>
                                    <tr>
                                        <th>Estado</th>
                                        <th>Calificación Final</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Completado</td>
                                        <td>{alumnoData.CalificacionFinal}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        )}

                        {mostrarCamposAlumno && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Seleccione el numero de clase a revisar</Form.Label>
                                    <Form.Select
                                        name="classnum"
                                        className="mb-3"
                                        value={alumnoData.claseSeleccionada || ''}
                                        onChange={handleClaseChange}
                                    >
                                        <option value="">Seleccione el número de clase</option>
                                        <option value="class1">Clase 1</option>
                                        <option value="class2">Clase 2</option>
                                        <option value="class3">Clase 3</option>
                                        <option value="class4">Clase 4</option>
                                    </Form.Select>
                                </Form.Group>

                                {renderCampoClaseSeleccionada()}

                                <Form.Group className="mb-3">
                                    <Form.Label>Calificacion del proyecto</Form.Label>
                                    <Form.Select
                                        name="proyecto"
                                        className="mb-3"
                                        value={alumnoData.Proyecto || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Proyecto: e.target.value
                                        })}
                                        disabled
                                    >
                                        <option value="">Seleccione la calificacion del proyecto</option>
                                        <option value="E">E</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="N">N</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Calificación del examen</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="calificacion"
                                        placeholder="Ingresa la calificación"
                                        min="0"
                                        max="100"
                                        value={alumnoData.Examen || ''}
                                        onChange={(e) => {
                                            const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limita entre 0 y 100
                                            setAlumnoData({
                                                ...alumnoData,
                                                Examen: value
                                            });
                                        }}
                                        disabled
                                    />
                                </Form.Group>


                                <Form.Group className="mb-3">
                                    <Form.Label>Participacion</Form.Label>
                                    <Form.Select
                                        name="participacion"
                                        className="mb-3"
                                        value={alumnoData.Participacion || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Participacion: e.target.value
                                        })}
                                        disabled
                                    >
                                        <option value="">Seleccione la calificacion de la participacion</option>
                                        <option value="E">E</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="N">N</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Comentarios</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="comentarios"
                                        placeholder="Escribe algún comentario"
                                        value={alumnoData.Comentarios || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Comentarios: e.target.value
                                        })}
                                        disabled
                                    />
                                </Form.Group>

                                {alumnoData.ID && alumnoData.CalificacionFinal && (
                                    <button className="btn btn-outline-success ml-2" onClick={handleCrearExcel}>
                                        Guardar xls
                                    </button>
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default MinutaConsultor_Card;
