// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #f1feef;
}

.container-fluid {
    padding: 20px;
}

.table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.btn-info {
    background-color: #608334;
    border-color: #608334;
}

.navbar-brand {
    color: white !important;
}

.nav-link {
    color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/stylesGeneral.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/* styles.css */\nbody, html {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    width: 100%;\n    background-color: #f1feef;\n}\n\n.container-fluid {\n    padding: 20px;\n}\n\n.table th, .table td {\n    vertical-align: middle;\n    text-align: center;\n}\n\n.btn-info {\n    background-color: #608334;\n    border-color: #608334;\n}\n\n.navbar-brand {\n    color: white !important;\n}\n\n.nav-link {\n    color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
