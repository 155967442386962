import React from 'react';
import EncuestaCard from '../components/llenadoEncuestaCard';

const EncuestasLlenado = () => {
    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <div className="flex-grow-1">
                <EncuestaCard />
            </div>
        </div>

    );
};

export default EncuestasLlenado;