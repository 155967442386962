import React from 'react';
import NavbarCard from '../components/navbar_card';
import NavbarAllCard from '../components/navbarAllUsers';

const UsuariosEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <NavbarAllCard />
            </div>
        </div>
    );
};

export default UsuariosEditor;