import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdEditor_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [editorData, setEditorData] = useState({
        ID: '',
        username: '',
        password: '',
        IDrol: 2,
        exists: 1,
    });

    const handleCrearEditores = () => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.post('/actualizarUsuario', editorData)
                .then((response) => {
                    console.log(response);
                    navigate('/Editor');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            axiosInstance.post('/crearUsuario', editorData)
                .then((response) => {
                    console.log(response);
                    navigate('/Editor');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerEdicionUsuario?id=${searchParam}`)
                .then(response => {
                    const EditorArray = response.data;

                    if (Array.isArray(EditorArray) && EditorArray.length > 0) {
                        const editor = EditorArray[0]; // Acceder al primer objeto en el array

                        setEditorData({
                            ID: editor.ID,
                            IDrol: editor.ID_Rol,
                            username: editor.Nombre,
                            password: editor.Contraseña,
                            exists: editor.Existe,
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo editor:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Editor</h5>
                        <p className="card-text">Ingresa el username del editor</p>
                        <InputText
                            type="text"
                            name="username"
                            value={editorData.username}
                            onChange={(e) => setEditorData({ ...editorData, username: e.target.value })}
                        />
                        <p className="card-text">Ingresa la contraseña del editor</p>
                        <InputText
                            type="text"
                            name="password"
                            value={editorData.password}
                            onChange={(e) => {
                                setEditorData({ ...editorData, password: e.target.value });
                                // Log al actualizar el password
                                console.log('Updated password:', e.target.value);
                            }}
                        />
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearEditores}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdEditor_Card;
