// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* navbarAllCard.css */
.navbar-all-card {
    width: 100%;
    height: 20%;
    background-color: #608334;
}
`, "",{"version":3,"sources":["webpack://./src/css/navbar.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":["/* navbarAllCard.css */\n.navbar-all-card {\n    width: 100%;\n    height: 20%;\n    background-color: #608334;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
