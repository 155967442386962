import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Table } from 'react-bootstrap';
import InputText from '../components/input_text';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Maestros_Card = () => {
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedMaestros, setSelectedMaestros] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleEdicion = () => {
        navigate('/AddMaestros');
    };

    useEffect(() => {
        axiosInstance.get('/ListaMaestros')
            .then(response => {
                setSelectedMaestros(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        searchMaestros(e.target.value);
    };
    const searchMaestros = (term) => {
        axiosInstance.get(`/ListaMaestrosBuscar`, {
            params: { maestro: `%${term}%` } // Aquí usamos % para búsqueda parcial
        })
            .then(response => {
                setSelectedMaestros(response.data);
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                    <h5 className="card-title">Lista de maestros</h5>
                        <p className="card-text">Buscar por maestro</p>
                        <InputText
                            type="text"
                            name="maestro"
                            onChange={handleSearchChange}
                        />
                        <button onClick={handleEdicion}>Crear</button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>UserName</th>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Numero</th>
                                    <th>Existe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedMaestros.slice().reverse().map((maestro, index) => (
                                    <tr key={index} onClick={() => handleShow(maestro)}>
                                        <td>{maestro.UsuarioNombre}</td>
                                        <td>{maestro.MaestroNombre}</td>
                                        <td>{maestro.MaestroCorreo}</td>
                                        <td>{maestro.MaestroNumero}</td>
                                        <td>{maestro.Existe}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles del Maestro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>ID del maestro:</strong> {selectedRow && selectedRow.MaestroID ? selectedRow.MaestroID : 'Null'}</p>
                                <p><strong>Nombre de Usuario:</strong> {selectedRow.UsuarioNombre}</p>
                                <p><strong>Nombre completo:</strong> {selectedRow.MaestroNombre}</p>
                                <p><strong>Correo completo:</strong> {selectedRow.MaestroCorreo}</p>
                                <p><strong>Numero completo:</strong> {selectedRow.MaestroNumero}</p>
                                <p><strong>Existe:</strong> {selectedRow.Existe}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Link to={`/AddMaestros?id=${selectedRow && selectedRow.MaestroID ? selectedRow.MaestroID : 'Null'}`}>
                            <Button variant="primary">
                                Editar
                            </Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Maestros_Card;
