import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Table } from 'react-bootstrap';
import InputText from '../components/input_text';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Editor_Card = () => {
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedEditores, setSelectedEditores] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleEdicion = () => {
        navigate('/AddEditor');
    };

    useEffect(() => {
        const rolID = 2;
        axiosInstance.get(`/ListaUsuarios?id=${rolID}`)
            .then(response => {
                setSelectedEditores(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                        <form className="d-flex mb-3" role="search">
                            <span>Editor a buscar:</span>
                            <InputText type="text" name="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success ml-2" type="submit">Search</button>
                        </form>
                        <button onClick={handleEdicion}>Crear</button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>UserName</th>
                                    <th>Contraseña</th>
                                    <th>Existe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedEditores.map((editor, index) => (
                                    <tr key={index} onClick={() => handleShow(editor)}>
                                        <td>{editor && editor.ID ? editor.ID : 'Null'}</td>
                                        <td>{editor.Nombre}</td>
                                        <td>{editor.Contraseña}</td>
                                        <td>{editor.Existe}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles del Editor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>ID del editor:</strong> {selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}</p>
                                <p><strong>Nombre de Usuario:</strong> {selectedRow.Nombre}</p>
                                <p><strong>Contraseña:</strong> {selectedRow.Contraseña}</p>
                                <p><strong>Existe:</strong> {selectedRow.Existe}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Link to={`/AddEditor?id=${selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}`}>
                            <Button variant="primary">
                                Editar
                            </Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Editor_Card;
