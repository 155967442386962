import React from 'react';
import NavbarCard from '../components/navbar_card';
import SalonesCard from '../components/salones_card';

const SalonesEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <SalonesCard />
            </div>
        </div>
    );
};

export default SalonesEditor;