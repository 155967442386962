import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import InputText from '../components/input_text';
import axios from 'axios';
import { format } from 'date-fns';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdEncuesta_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [grupoData, setGrupoData] = useState({
        EncuestaId: '',
        grupoId: '',
        fechaInicio: '',
        fechaTerminacion: '',
        Tipo: '',
        Cantidad: '',
    });

    const [grupos, setGrupos] = useState([]);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);

    const handleCrearEncuesta = () => {
        const endpoint = isUpdate ? 'actualizarEncuesta' : 'crearEncuesta';
        axiosInstance.post(`/${endpoint}`, grupoData)
            .then((response) => {
                console.log(response);
                navigate('/encuestas');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerEdicionEncuesta?id=${searchParam}`)
                .then(response => {
                    const grupoArray = response.data;
                    if (Array.isArray(grupoArray) && grupoArray.length > 0) {
                        const oferta = grupoArray[0];
                        setGrupoData({
                            EncuestaId: oferta.ID,
                            grupoId: oferta.GrupoID,
                            fechaInicio: oferta.FechaInicio || '',
                            fechaTerminacion: oferta.FechaFin || '',
                            Tipo: oferta.Tipo,
                            Cantidad: oferta.Cantidad,
                        });
                        setIsUpdate(true);
                    } else {
                        setIsUpdate(false);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo encuestas:', error);
                });
        }
    }, [searchParam]);

    useEffect(() => {
        axiosInstance.get('/ListaGrupos')
            .then(response => {
                setGrupos(response.data);
            })
            .catch(error => {
                console.error('Error fetching grupos:', error);
            });
    }, []);

    const formatDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return isNaN(date) ? '' : format(date, 'yyyy-MM-dd');
        } catch (e) {
            console.error('Error formatting date:', e);
            return '';
        }
    };

    const today = new Date();

    const handleGrupoChange = (e) => {
        const grupoId = e.target.value;
        if (grupoId === "") {
            setGrupoSeleccionado(null); // Limpiar la tabla si se selecciona la opción vacía
            setGrupoData({
                EncuestaId: '',
                grupoId: '',
                fechaInicio: '',
                fechaTerminacion: '',
                Tipo: '',
                Cantidad: '',
            });
            setIsUpdate(false);
        } else {
            const grupo = grupos.find(grupo => grupo.GrupoID === parseInt(grupoId));
            setGrupoSeleccionado(grupo);

            axiosInstance.get(`/obtenerEdicionEncuesta?id=${grupoId}`)
                .then(response => {
                    const grupoArray = response.data;
                    if (Array.isArray(grupoArray) && grupoArray.length > 0) {
                        const oferta = grupoArray[0];
                        setGrupoData({
                            EncuestaId: oferta.ID,
                            grupoId: oferta.GrupoID,
                            fechaInicio: oferta.FechaInicio || '',
                            fechaTerminacion: oferta.FechaFin || '',
                            Tipo: oferta.Tipo,
                            Cantidad: oferta.Cantidad,
                        });
                        setIsUpdate(true);
                    } else {
                        setIsUpdate(false);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo encuestas:', error);
                });


        }
    };

    const isSelect1Filled = !!grupoData[`grupoId`];

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Agregar Encuesta</h5>

                        <p className="card-text">Selecciona el grupo</p>
                        <Form.Select
                            name="grupo"
                            className="mb-3"
                            value={grupoData.grupoId}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    grupoId: e.target.value
                                });
                                handleGrupoChange(e);
                            }}
                        >
                            <option value="">Seleccione un grupo</option>
                            {grupos
                                .filter(grupo => {
                                    const isFechaValida = new Date(grupo.Fecha_Final) >= today;
                                    const isGrupoSearchParam = searchParam && grupo.GrupoID === parseInt(searchParam);
                                    // Incluir el grupo si tiene Fecha_Final válida o coincide con el searchParam
                                    return isFechaValida || isGrupoSearchParam;
                                })
                                .map(grupo => (
                                    <option key={grupo.GrupoID} value={grupo.GrupoID}>
                                        {grupo.Numero_Salon + '-' + grupo.Oferta}
                                    </option>
                                ))}
                        </Form.Select>

                        <Table>
                            <thead>
                                <tr>
                                    <td>Instructor</td>
                                    <td>Salon</td>
                                    <td>Periodo</td>
                                </tr>
                            </thead>
                            <tbody>
                                {grupoSeleccionado ? (
                                    <tr>
                                        <td>{grupoSeleccionado.Maestro}</td>
                                        <td>{grupoSeleccionado.Numero_Salon}</td>
                                        <td>{grupoSeleccionado.Periodo}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="9" className="text-center">No hay datos para mostrar</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>


                        <p className="card-text">Fecha de Inicio de Encuesta</p>
                        <InputText
                            type="date"
                            name="fechaInicio"
                            value={formatDate(grupoData.fechaInicio)}
                            onChange={(e) => setGrupoData({ ...grupoData, fechaInicio: e.target.value })}
                            disabled={!isSelect1Filled}
                        />
                        <p className="card-text">Fecha de Terminación de Encuesta</p>
                        <InputText
                            type="date"
                            name="fechaTerminacion"
                            value={formatDate(grupoData.fechaTerminacion)}
                            onChange={(e) => setGrupoData({ ...grupoData, fechaTerminacion: e.target.value })}
                            disabled={!isSelect1Filled}
                        />
                        <p className="card-text">Ingresa el tipo de encuesta</p>
                        <Form.Select
                            name="tipo"
                            className="mb-3"
                            value={grupoData.Tipo}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    Tipo: e.target.value
                                });
                            }}
                            disabled={!isSelect1Filled}
                        >
                            <option value="">Seleccione el tipo de encuesta</option>
                            <option value="Satisfaccion personal">Encuesta de satisfaccion personal</option>
                            <option value="Maestros">Encuesta Maestros</option>
                            <option value="Servicio Social">Encuesta de satisfaccion servicio social</option>
                        </Form.Select>
                        <Form.Group className="mb-3">
                            <Form.Label>Ingrese la cantidad de alumnos</Form.Label>
                            <Form.Control
                                type="number"
                                name="cantidad"
                                placeholder="Ingresa la cantidad"
                                min="0"
                                max="100"
                                value={grupoData.Cantidad || ''}
                                onChange={(e) => {
                                    const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limita entre 0 y 100
                                    setGrupoData({
                                        ...grupoData,
                                        Cantidad: value
                                    });
                                }}
                                disabled={!isSelect1Filled}
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-success ml-2" onClick={handleCrearEncuesta} disabled={!isSelect1Filled} >
                                {isUpdate ? 'Actualizar' : 'Confirmar'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdEncuesta_Card;
