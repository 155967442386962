import {BrowserRouter,Routes,Route} from "react-router-dom";
import './App.css';
import Login from './pages/login';
import LandingPage from './pages/landingPage';
import LandingPageTeacher from './pages/landingMaestro';
import LandingPageAdmin from './pages/landingAdmin';
import LandingPageConsulta from './pages/landingConsulta';
import LandingPageRevisor from './pages/landingRevisor';
import AreasEditor from './pages/areasEditor';
import OfertasEditor from './pages/ofertasEditor';
import ModulosEditor from './pages/modulosEditor';
import MaestrosEditor from './pages/maestrosEditor';
import AlumnosEditor from './pages/alumnosEditor';
import SalonesEditor from './pages/salonesEditor';
import EncuestasEditor from './pages/encuestasEditor';
import ReportesEditor from './pages/reportesEditor';
import UsuariosEditor from './pages/usuariosEditor';
import GruposEditor from './pages/gruposEditor';
import MaestrosAdd from './pages/maestrosAdd';
import AlumnosAdd from './pages/alumnosAdd';
import SalonesAdd from './pages/salonesAdd';
import AreasAdd from './pages/areasAdd';
import OfertasAdd from './pages/ofertasAdd';
import ModulosAdd from './pages/modulosAdd';
import GruposAdd from './pages/gruposAdd';
import AlumnosGruposAdd from './pages/alumnosGrupos';
import EncuestasAdd from './pages/encuestasAdd';

import EditorAdmin from './pages/editorAdmin';
import ConsultorAdmin from './pages/consultorAdmin';
import RevisorAdmin from './pages/revisorAdmin';
import AdministradorAdd from './pages/administradorAdd';
import EditorAdd from './pages/editorAdd';
import ConsultorAdd from './pages/consultorAdd';
import RevisorAdd from './pages/revisorAdd';

import LandingEncuestas from './pages/landingEncuestas';
import EncuestaAdd from './pages/encuestaLlenado';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />}/>
            <Route exact path="/landingPage" element={<LandingPage />}/>
            <Route exact path="/landingPageTeacher" element={<LandingPageTeacher />}/>
            <Route exact path="/landingPageAdmin" element={<LandingPageAdmin />}/>
            <Route exact path="/landingPageConsulta" element={<LandingPageConsulta />}/>
            <Route exact path="/landingPageRevisor" element={<LandingPageRevisor />}/>
            <Route exact path="/areas" element={<AreasEditor />}/>
            <Route exact path="/ofertas" element={<OfertasEditor />}/>
            <Route exact path="/modulos" element={<ModulosEditor />}/>
            <Route exact path="/maestros" element={<MaestrosEditor />}/>
            <Route exact path="/alumnos" element={<AlumnosEditor />}/>
            <Route exact path="/salones" element={<SalonesEditor />}/>
            <Route exact path="/encuestas" element={<EncuestasEditor />}/>
            <Route exact path="/reportes" element={<ReportesEditor />}/>
            <Route exact path="/usuarios" element={<UsuariosEditor />}/>
            <Route exact path="/AddMaestros" element={<MaestrosAdd />}/>
            <Route exact path="/AddAlumnos" element={<AlumnosAdd />}/>
            <Route exact path="/AddSalones" element={<SalonesAdd />}/>
            <Route exact path="/AddAreas" element={<AreasAdd />}/>
            <Route exact path="/AddOfertas" element={<OfertasAdd />}/>
            <Route exact path="/AddModulos" element={<ModulosAdd />}/>
            <Route exact path="/grupos" element={<GruposEditor />}/>
            <Route exact path="/AddGrupos" element={<GruposAdd />}/>
            <Route exact path="/AGADD" element={<AlumnosGruposAdd />}/>
            <Route exact path="/AddEncuestas" element={<EncuestasAdd />}/>
            <Route exact path="/Editor" element={<EditorAdmin />}/>
            <Route exact path="/Consultor" element={<ConsultorAdmin />}/>
            <Route exact path="/Revisor" element={<RevisorAdmin />}/>
            <Route exact path="/AddAdministrador" element={<AdministradorAdd />}/>
            <Route exact path="/AddEditor" element={<EditorAdd />}/>
            <Route exact path="/AddConsultor" element={<ConsultorAdd />}/>
            <Route exact path="/AddRevisor" element={<RevisorAdd />}/>
            <Route exact path="/LandingEncuestas" element={<LandingEncuestas />}/>
            <Route exact path="/EncuestaLlenado" element={<EncuestaAdd />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
