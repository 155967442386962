import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdModulo_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [moduloData, setModuloData] = useState({
        nameModulo: '',
        nameOferta: '',
        exists: 1,
        moduloId: '',
        ofertaId: '',
    });

    const [selectedOferta, setSelectedOferta] = useState([]);

    const handleCrearModulo = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar maestro existente
            axiosInstance.post('/actualizarModulo', moduloData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nuevo maestro
            axiosInstance.post('/crearModulo', moduloData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos de la oferta si hay un ID
            axiosInstance.get(`/obtenerEdicionModulo?id=${searchParam}`)
                .then(response => {
                    const ofertaArray = response.data;
                    console.log('Response from obtenerEdicionModulo:', response);

                    if (Array.isArray(ofertaArray) && ofertaArray.length > 0) {
                        const oferta = ofertaArray[0]; // Acceder al primer objeto en el array

                        setModuloData({
                            nameOferta: oferta.ofertaNombre,
                            nameModulo: oferta.moduloNombre,
                            exists: oferta.moduloExiste,
                            ofertaId: oferta.IdOferta,
                            moduloId: oferta.IdModulo
                        });

                        console.log('Updated moduloData after API call:', {
                            nameOferta: oferta.ofertaNombre,
                            nameModulo: oferta.moduloNombre,
                            exists: oferta.moduloExiste,
                            ofertaId: oferta.IdOferta,
                            moduloId: oferta.IdModulo
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo oferta:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);

    useEffect(() => {
        axiosInstance.get('/ListaOfertas')
            .then(response => {
                setSelectedOferta(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Modulo</h5>
                        <p className="card-text">Ingresa el nombre del modulo</p>
                        <InputText
                            type="text"
                            name="modulo"
                            value={moduloData.nameModulo}
                            onChange={(e) => setModuloData({ ...moduloData, nameModulo: e.target.value })}
                        />
                        <p className="card-text">Ingresa la oferta asignada</p>
                        <Form.Select
                            name="oferta"
                            className="mb-3"
                            value={moduloData.ofertaId}
                            onChange={(e) => {
                                const selectedOf = selectedOferta.find(area => area.IdOferta === parseInt(e.target.value));
                                console.log('Cambio en combobox área:', e.target.value, selectedOf);
                                setModuloData({ 
                                    ...moduloData, 
                                    ofertaId: e.target.value, 
                                    nameOferta: selectedOf ? selectedOf.ofertaNombre : '' 
                                });
                            }}
                        >
                            <option value="">Seleccione una oferta</option>
                            {selectedOferta.map(area => (
                                <option key={area.IdOferta} value={area.IdOferta}>{area.ofertaNombre}</option>
                            ))}
                        </Form.Select>
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearModulo}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdModulo_Card;
