import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/navbarCard.css';

const NavbarCard = () => {
    const [userName, setUsername] = useState('');
    const [initialRedirectDone, setInitialRedirectDone] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        // Eliminar datos del localStorage
        localStorage.clear();
        // Redirigir a otra p�gina
        navigate('/login');
    };

    useEffect(() => {
        // Recuperar el nombre del usuario del localStorage
        if (!initialRedirectDone) {
            const rol = localStorage.getItem('Rol');

            if (rol != null) {
                if (rol === '1') {
                    navigate('/landingPageAdmin');
                } else if (rol === '3') {
                    navigate('/landingPageConsulta');
                } else if (rol === '4') {
                    navigate('/landingPageRevisor');
                } else if (rol === '5') {
                    navigate('/landingPageTeacher');
                } else {
                    console.log("Credenciales Correctas");
                }
            } else {
                navigate('/login');
            }

            // Establece que la redirección inicial ya se realizó
            setInitialRedirectDone(true);
        }


        const storedUsername = localStorage.getItem('userData');
        const storedUsername2 = storedUsername ? JSON.parse(storedUsername) : null;
        if (storedUsername2) {
            setUsername(storedUsername2);
        }
    }, [initialRedirectDone, navigate]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="navbar-card">
            <nav className="navbar">
                <button className="nav-link" onClick={() => handleNavigation('/landingPage')}>SGE - FIME</button>
                <button className="nav-link" onClick={() => handleNavigation('/landingPage')}>Dashboard</button>
                <button className="nav-link" onClick={() => handleNavigation('/areas')}>Areas</button>
                <button className="nav-link" onClick={() => handleNavigation('/ofertas')}>Ofertas</button>
                <button className="nav-link" onClick={() => handleNavigation('/modulos')}>Modulos</button>
                <button className="nav-link" onClick={() => handleNavigation('/maestros')}>Maestros</button>
                <button className="nav-link" onClick={() => handleNavigation('/alumnos')}>Alumnos</button>
                <button className="nav-link" onClick={() => handleNavigation('/salones')}>Salones</button>
                <button className="nav-link" onClick={() => handleNavigation('/grupos')}>Grupos</button>
                <button className="nav-link" onClick={() => handleNavigation('/encuestas')}>Encuestas</button>
                <button className="nav-link" onClick={() => handleNavigation('/reportes')}>Reportes</button>
                <button className="nav-link" onClick={handleLogout}>Salir de la sesion</button>
                <button className="nav-link" onClick={() => handleNavigation('/landingPage')}>{userName}</button>
            </nav>
        </div>
    );
};

export default NavbarCard;
