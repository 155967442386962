import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import InputText from '../components/input_text';
import ButtonSubmit from '../components/button_submit';
import axiosInstance from '../components/url_data';
import axios from 'axios';

import '../css/login.css';

const LoginCard = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance.post('/login', {
      username: username,
      password: password
    })
      .then((response) => {
        console.log(response);
        if (response.data.alert === 'Success') {
          const { username, id, rol } = response.data;
          localStorage.setItem('userData', JSON.stringify(username));
          localStorage.setItem('userId', JSON.stringify(id));
          localStorage.setItem('Rol', JSON.stringify(rol));
          if (response.data.rol === 1) {
            navigate('/landingPageAdmin');
          }
          else if (response.data.rol === 2) {
            navigate('/landingPage')
          }
          else if (response.data.rol === 3) {
            navigate('/landingPageConsulta')
          }
          else if (response.data.rol === 4) {
            navigate('/landingPageRevisor')
          }
          else if (response.data.rol === 5) {
            navigate('/landingPageTeacher')
          }
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.clear();
        setErrorMessage('Las credenciales no son válidas, intenta nuevamente.');
      });
  };

  /*const handleLoginClick = () => {
    navigate('/landingPage');
  };
  
  useEffect(() => {
    document.getElementById('btn_submit').addEventListener('click', handleLoginClick);
  }, []);*/

  useEffect(() => {
    const rol = localStorage.getItem('Rol');

    if (rol != null) {
      if (rol == 1) {
        navigate('/landingPageAdmin');
      }
      else if (rol == 2) {
        navigate('/landingPage');
      }
      else if (rol == 3) {
        navigate('/landingPageConsulta');
      }
      else if (rol == 4) {
        navigate('/landingPageRevisor');
      }
      else if (rol == 5) {
        navigate('/landingPageTeacher');
      } else {
        console.log("Error en credenciales");
      }
    } else {
      navigate('/login');
    }

  }, [navigate]);

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div className='container pt-4'>
        <Card className="mb-3">
          <Card.Body>
            <h2 className="card-title text-center mb-3">Inicio de Sesión</h2>
            <form className='row h-100 justify-content-center align-items-center' style={{ minHeight: '200px' }} onSubmit={handleSubmit}>
              <div className='col-md-9 text-center'>
                <span>Nombre de usuario:</span>
                <InputText type="text" name="user" id="user" value={username} onChange={(e) => {
                  setUsername(e.target.value);
                }} />
              </div>
              <div className='col-md-9 text-center'>
                <span>Contraseña:</span>
                <InputText type="password" name="pass" id="pass" value={password} onChange={(e) => {
                  setPassword(e.target.value);
                }} />
              </div>
              <div className='col-md-6 text-center mb-3 mt-2'>
                <ButtonSubmit type="submit" name="btn_submit" id="btn_submit" value="Iniciar Sesión" />
              </div>
              {errorMessage && <div className="col-md-12 text-center text-danger">{errorMessage}</div>}
            </form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default LoginCard;