import React from 'react';
import NavbarCard from '../components/navbar_card';
import ReportesCard from '../components/reportes_card';

const ReportesEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <ReportesCard />
            </div>
        </div>
    );
};

export default ReportesEditor;