import React from 'react';
import NavbarCard from '../components/navbar_card';
import AreasCard from '../components/areas_card';

const AreasEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <AreasCard />
            </div>
        </div>
    );
};

export default AreasEditor;