import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Llenado_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const [selectedEncuestas, setSelectedEncuestas] = useState([
        {
            id: '', dominio: '', horario: '', trato: '', dudas: '', evaluacion: '',
            objetivos: '', practicas: '', organizacion: '', curso: '', instalaciones: '',
            servicioCliente: '', promedio: '', temario: '', condiciones: '',
            estado: '', limpieza: '', servicio: '', respuesta: '', comentarios: ''
        }
    ]);

    const navigate = useNavigate();

    const [encuestaData, setEncuestaData] = useState({
        id: searchParam,
        dominio: '',
        horario: '',
        trato: '',
        dudas: '',
        evaluacion: '',
        objetivos: '',
        practicas: '',
        organizacion: '',
        curso: '',         // Curso
        instalaciones: '',  // Instalaciones
        servicioCliente: '', // Servicio Cliente
        promedio: '', //Instructor
        temario: '',
        condiciones: '',
        estado: '',
        limpieza: '',
        servicio: '',
        respuesta: '',
        comentarios: ''
    });

    const calcularPromedios = () => {
        // Crear una copia de selectedEncuestas y encuestaData para evitar mutaciones directas
        const encuestaActualizada = { ...encuestaData };

        // Promediar cada valor individualmente entre selectedEncuestas y encuestaData
        const keysToAverage = [
            'dominio', 'horario', 'trato', 'dudas', 'evaluacion',
            'objetivos', 'practicas', 'organizacion', 'curso', 'temario',
            'condiciones', 'estado', 'limpieza', 'servicio',
            'respuesta'
        ];

        keysToAverage.forEach((key) => {
            const value1 = parseFloat(selectedEncuestas[key]) || 0;
            const value2 = parseFloat(encuestaData[key]) || 0;
            encuestaActualizada[key] = ((value1 + value2) / 2).toFixed(2); // Redondeo a 2 decimales
        });

        // Calcular el promedio de las categorías agrupadas
        encuestaActualizada.promedio = (
            (parseFloat(encuestaActualizada.dominio) +
                parseFloat(encuestaActualizada.horario) +
                parseFloat(encuestaActualizada.trato) +
                parseFloat(encuestaActualizada.dudas) +
                parseFloat(encuestaActualizada.evaluacion) +
                parseFloat(encuestaActualizada.objetivos) +
                parseFloat(encuestaActualizada.practicas) +
                parseFloat(encuestaActualizada.organizacion)) / 8
        ).toFixed(2);

        encuestaActualizada.curso = (parseFloat(encuestaActualizada.temario)).toFixed(2);

        encuestaActualizada.instalaciones = (
            (parseFloat(encuestaActualizada.condiciones) +
                parseFloat(encuestaActualizada.estado) +
                parseFloat(encuestaActualizada.limpieza)) / 3
        ).toFixed(2);

        encuestaActualizada.servicioCliente = (
            (parseFloat(encuestaActualizada.respuesta) +
                parseFloat(encuestaActualizada.servicio)) / 2
        ).toFixed(2);

        const comentarios1 = selectedEncuestas.comentarios || '';
        const comentarios2 = encuestaData.comentarios || '';
        encuestaActualizada.comentarios = comentarios1 && comentarios2
            ? `${comentarios1}, ${comentarios2}`
            : comentarios1 || comentarios2;

        console.log(encuestaActualizada);

        // Actualizar encuestaData con los promedios calculados
        /*setEncuestaData(encuestaActualizada);*/
        console.log(encuestaData);

        // Actualizar maestro existente
        axiosInstance.post('/llenarEncuesta', encuestaActualizada)
            .then((response) => {
                console.log(response);
                navigate('/LandingEncuestas');
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const handleLlenarEncuesta = () => {
        if (searchParam && searchParam !== 'Null') {
            calcularPromedios();
        }
    };


    useEffect(() => {
        if (searchParam && searchParam === 'Null') {
            navigate('/LandingEncuestas');
        } else {
            axiosInstance.get(`/ListaPromediosBuscar?id=${searchParam}`)
                .then(response => {
                    const encuestaArray = response.data;

                    if (Array.isArray(encuestaArray) && encuestaArray.length > 0) {
                        const encuesta = encuestaArray[0]; // Acceder al primer objeto en el array

                        setSelectedEncuestas({
                            id: encuesta.ID || '', dominio: encuesta.Dominio || '', horario: encuesta.Horario || '', trato: encuesta.Trato || '',
                            dudas: encuesta.Dudas || '', evaluacion: encuesta.Evaluacion || '',
                            objetivos: encuesta.Objetivos || '', practicas: encuesta.Practicas || '',
                            organizacion: encuesta.Organizacion || '', curso: encuesta.Curso || '',
                            instalaciones: encuesta.Instalaciones || '', servicioCliente: encuesta.ServicioCliente || '',
                            promedio: encuesta.Promedio || '', temario: encuesta.Temario || '',
                            condiciones: encuesta.Condiciones || '', estado: encuesta.Estado || '',
                            limpieza: encuesta.Limpieza || '', servicio: encuesta.Servicio || '',
                            respuesta: encuesta.Respuesta || '', comentarios: encuesta.Comentarios || ''
                        });

                        console.log('Updated selectedEncuesta after API call:', {
                            id: encuesta.ID || '', dominio: encuesta.Dominio || '', horario: encuesta.Horario || '', trato: encuesta.Trato || '',
                            dudas: encuesta.Dudas || '', evaluacion: encuesta.Evaluacion || '',
                            objetivos: encuesta.Objetivos || '', practicas: encuesta.Practicas || '',
                            organizacion: encuesta.Organizacion || '', curso: encuesta.Curso || '',
                            instalaciones: encuesta.Instalaciones || '', servicioCliente: encuesta.ServicioCliente || '',
                            promedio: encuesta.Promedio || '', temario: encuesta.Temario || '',
                            condiciones: encuesta.Condiciones || '', estado: encuesta.Estado || '',
                            limpieza: encuesta.Limpieza || '', servicio: encuesta.Servicio || '',
                            respuesta: encuesta.Respuesta || '', comentarios: encuesta.Comentarios || ''

                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching encuestas:', error);
                });
        }
    }, [searchParam]);

    const handleInputChange = (e) => {
        const { name, value } = e.target; // Obtener el nombre y el valor del campo
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        // Si el valor comienza con '0' y es diferente de '0', no permitir más caracteres
        if (numericValue.length > 0 && numericValue[0] === '0') {
            setEncuestaData({ ...encuestaData, [name]: '0' }); // Asignar '0' si comienza con 0
        }
        // Permitir solo números entre 0 y 100
        else if (numericValue === '' || (parseInt(numericValue, 10) >= 1 && parseInt(numericValue, 10) <= 10)) {
            setEncuestaData({ ...encuestaData, [name]: numericValue });
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Llenado de encuesta</h5>
                        <p className="card-text mb-3">Ingrese la calificación que a su criterio sea la conveniente, considere 1 como deficiente y 10 como excelente</p>
                        <p className="card-text">Agradecemos que enriquezca la evaluación con comentarios al final de las preguntas.</p>
                        <h5 className="card-title">Instructor</h5>
                        <p className="card-text">¿Cómo considera usted, el dominio del curso por parte del instructor?</p>
                        <InputText
                            type="text"
                            name="dominio"
                            value={encuestaData.dominio}
                            onChange={handleInputChange}
                        />
                        <p className="card-text">¿En qué grado, el instructor cumplió con los horarios de entrada y salida?</p>
                        <InputText
                            type="text"
                            name="horario"
                            value={encuestaData.horario}
                            onChange={handleInputChange}
                            placeholder="Horario"
                        />
                        <p className="card-text">¿Cómo considera el trato del instructor hacia usted durante el curso?</p>
                        <InputText
                            type="text"
                            name="trato"
                            value={encuestaData.trato}
                            onChange={handleInputChange}
                            placeholder="Trato"
                        />
                        <p className="card-text">¿En qué medida el instructor aclaró dudas sobre los temas expuestos?</p>
                        <InputText
                            type="text"
                            name="dudas"
                            value={encuestaData.dudas}
                            onChange={handleInputChange}
                            placeholder="Dudas"
                        />
                        <p className="card-text">¿En qué grado el instructor dió a conocer los criterios de evaluación y cumplió con ellos?</p>
                        <InputText
                            type="text"
                            name="evaluacion"
                            value={encuestaData.evaluacion}
                            onChange={handleInputChange}
                            placeholder="Evaluación"
                        />
                        <p className="card-text">¿En qué medida el instructor cumplió con el objetivo y temario del curso?</p>
                        <InputText
                            type="text"
                            name="objetivos"
                            value={encuestaData.objetivos}
                            onChange={handleInputChange}
                            placeholder="Objetivos"
                        />
                        <p className="card-text">¿En qué grado las prácticas realizadas, reforzaron lo aprendido en clase?</p>
                        <InputText
                            type="text"
                            name="practicas"
                            value={encuestaData.practicas}
                            onChange={handleInputChange}
                            placeholder="Prácticas"
                        />
                        <p className="card-text">¿Cómo considera la organización y presentación del instructor?</p>
                        <InputText
                            type="text"
                            name="organizacion"
                            value={encuestaData.organizacion}
                            onChange={handleInputChange}
                            placeholder="Organización"
                        />
                        <h5 className="card-title">Curso</h5>
                        <p className="card-text">¿Cómo evalúa el temario y contenido teórico del curso?</p>
                        <InputText
                            type="text"
                            name="temario"
                            value={encuestaData.temario}
                            onChange={handleInputChange}
                            placeholder="Temario"
                        />
                        <h5 className="card-title">Instalaciones</h5>
                        <p className="card-text">¿Cómo califica las condiciones del aula, sala de cómputo y/o laboratorios?</p>
                        <InputText
                            type="text"
                            name="condiciones"
                            value={encuestaData.condiciones}
                            onChange={handleInputChange}
                            placeholder="Condiciones"
                        />
                        <p className="card-text">¿Cuál es el estado del equipo y herramienta utilizado en el curso?</p>
                        <InputText
                            type="text"
                            name="estado"
                            value={encuestaData.estado}
                            onChange={handleInputChange}
                            placeholder="Estado"
                        />
                        <p className="card-text">¿Cómo evalúa el orden y limpieza del aula, sala de cómputo y/o laboratorio?</p>
                        <InputText
                            type="text"
                            name="limpieza"
                            value={encuestaData.limpieza}
                            onChange={handleInputChange}
                            placeholder="Limpieza"
                        />
                        <h5 className="card-title">Servicio al cliente</h5>
                        <p className="card-text">¿Cómo evalúa la atención y servicio por parte de nuestro personal durante el curso?</p>
                        <InputText
                            type="text"
                            name="servicio"
                            value={encuestaData.servicio}
                            onChange={handleInputChange}
                            placeholder="Servicio Cliente"
                        />
                        <p className="card-text">¿Cómo califica la atención y respuesta de nuestro personal de comunicación (Facebook, WhatsApp, Email, Telefónico)?</p>
                        <InputText
                            type="text"
                            name="respuesta"
                            value={encuestaData.respuesta}
                            onChange={handleInputChange}
                            placeholder="Respuesta"
                        />

                        <Form.Group className="mb-3">
                            <Form.Label>Comentarios</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="comentarios"
                                placeholder="Escribe algún comentario"
                                onChange={(e) => {
                                    setEncuestaData({ ...encuestaData, comentarios: e.target.value });
                                }}
                            />
                        </Form.Group>
                        <button className="btn btn-outline-success ml-2" onClick={handleLlenarEncuesta}>
                            {searchParam && searchParam !== 'Null' ? 'Confirmar' : 'Error'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Llenado_Card;
