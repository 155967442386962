import React from 'react';
import NavbarCard from '../components/navbar_card';
import GeneralCard from '../components/cardGeneral';

const LandingPage = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <GeneralCard />
            </div>
        </div>
    );
};

export default LandingPage;