import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdAlumnos_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [alumnoData, setAlumnoData] = useState({
        nameCompleto: '',
        exists: 1,
        alumnoId: '',
        matricula: '',
        numero: '',
        mail: ''
    });

    const handleCrearAlumnos = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar maestro existente
            axiosInstance.post('/actualizarAlumno', alumnoData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nuevo maestro
            axiosInstance.post('/crearAlumno', alumnoData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos del maestro si hay un ID
            axiosInstance.get(`/obtenerEdicionAlumno?id=${searchParam}`)
                .then(response => {
                    const alumnoArray = response.data;
                    console.log('Response from obtenerEdicionMaestro:', response);

                    if (Array.isArray(alumnoArray) && alumnoArray.length > 0) {
                        const alumno = alumnoArray[0]; // Acceder al primer objeto en el array

                        setAlumnoData({
                            nameCompleto: alumno.Nombre,
                            exists: alumno.Existe,
                            alumnoId: alumno.ID,
                            matricula: alumno.Matricula,
                            numero: alumno.Numero,
                            mail: alumno.Correo
                        });

                        console.log('Updated alumnoData after API call:', {
                            nameCompleto: alumno.Nombre,
                            exists: alumno.Existe,
                            alumnoId: alumno.ID,
                            matricula: alumno.Matricula,
                            numero: alumno.Numero,
                            mail: alumno.Correo
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo alumno:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);

    const handleMatriculaChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        if (numericValue === '' || parseInt(numericValue, 10) >= 1) {
            setAlumnoData({ ...alumnoData, matricula: numericValue });
        }
    };

    const handleNumeroChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        if (numericValue === '' || parseInt(numericValue, 10) >= 1) {
            setAlumnoData({ ...alumnoData, numero: numericValue });
            console.log(numericValue);
        }
    };



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Alumnos</h5>
                        <p className="card-text">Ingresa el nombre del Alumno</p>
                        <InputText
                            type="text"
                            name="name"
                            value={alumnoData.nameCompleto}
                            onChange={(e) => setAlumnoData({ ...alumnoData, nameCompleto: e.target.value })}
                        />
                        <p className="card-text">Ingresa la matricula del alumno</p>
                        <InputText
                            type="text"
                            name="matricula"
                            value={alumnoData.matricula}
                            onChange={handleMatriculaChange}
                        />
                        <p className="card-text">Ingresa el numero de telefono del alumno</p>
                        <InputText
                            type="text"
                            name="numero"
                            value={alumnoData.numero}
                            onChange={handleNumeroChange}
                        />
                        <p className="card-text">Ingresa el correo del alumno</p>
                        <InputText
                            type="text"
                            name="correo"
                            value={alumnoData.mail}
                            onChange={(e) => setAlumnoData({ ...alumnoData, mail: e.target.value })}
                        />
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearAlumnos}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdAlumnos_Card;
