import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdMaestros_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [maestroData, setMaestroData] = useState({
        nameCompleto: '',
        username: '',
        password: '12345',
        tipou: 5,
        exists: 1,
        userId: '',
        maestroId: '',
        mail: '',
        numero: ''
    });

    const handleCrearMaestros = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar maestro existente
            axiosInstance.post('/actualizarMaestro', maestroData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nuevo maestro
            axiosInstance.post('/crearMaestro', maestroData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos del maestro si hay un ID
            axiosInstance.get(`/obtenerEdicionMaestro?id=${searchParam}`)
                .then(response => {
                    const maestroArray = response.data;
                    console.log('Response from obtenerEdicionMaestro:', response);

                    if (Array.isArray(maestroArray) && maestroArray.length > 0) {
                        const maestro = maestroArray[0]; // Acceder al primer objeto en el array

                        setMaestroData({
                            nameCompleto: maestro.MaestroNombre,
                            username: maestro.UsuarioNombre,
                            password: maestro.Contraseña,
                            tipou: 5,
                            exists: maestro.Existe,
                            userId: maestro.userID,
                            maestroId: maestro.MaestroID,
                            mail: maestro.MaestroCorreo,
                            numero: maestro.MaestroNumero
                        });

                        console.log('Updated maestroData after API call:', {
                            nameCompleto: maestro.MaestroNombre,
                            username: maestro.UsuarioNombre,
                            password: maestro.Contraseña,
                            tipou: 5,
                            exists: maestro.Existe,
                            userId: maestro.userID,
                            maestroId: maestro.MaestroID,
                            mail: maestro.MaestroCorreo,
                            numero: maestro.MaestroNumero
                            
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo maestro:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        if (numericValue === '' || parseInt(numericValue, 10) >= 1) {
            setMaestroData({ ...maestroData, numero: numericValue });
        }
    };



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Maestro</h5>
                        <p className="card-text">Ingresa el nombre del maestro</p>
                        <InputText
                            type="text"
                            name="name"
                            value={maestroData.nameCompleto}
                            onChange={(e) => setMaestroData({ ...maestroData, nameCompleto: e.target.value })}
                        />
                        <p className="card-text">Ingresa el username del maestro</p>
                        <InputText
                            type="text"
                            name="username"
                            value={maestroData.username}
                            onChange={(e) => setMaestroData({ ...maestroData, username: e.target.value })}
                        />
                        <p className="card-text">Ingresa el correo del maestro</p>
                        <InputText
                            type="text"
                            name="correo"
                            value={maestroData.mail}
                            onChange={(e) => setMaestroData({ ...maestroData, mail: e.target.value })}
                        />
                        <p className="card-text">Ingresa el numero de telefono del salon</p>
                        <InputText
                            type="text"
                            name="numero"
                            value={maestroData.numero}
                            onChange={handleInputChange}
                        />
                        <p className="card-text">Ingresa la contraseña del maestro</p>
                        <InputText
                            type="text"
                            name="password"
                            value={maestroData.password}
                            onChange={(e) => {
                                setMaestroData({ ...maestroData, password: e.target.value });
                                // Log al actualizar el password
                                console.log('Updated password:', e.target.value);
                            }}
                        />
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearMaestros}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdMaestros_Card;
