import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { parseISO, addDays, format, isWithinInterval, isAfter } from 'date-fns';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Grupos_Card = () => {
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedGrupos, setSelectedGrupos] = useState([]);
    const [periodoGrupos, setPeriodoGrupos] = useState([]);

    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleEdicion = () => {
        navigate('/AddGrupos');
    };

    const formatDate = (dateString) => format(new Date(dateString), 'dd/MM/yyyy');

    const calcularCantidadClases = (fechaInicio, fechaFin, frecuencia) => {
        const diasFrecuencia = frecuencia.split(',').map(dia => {
            switch (dia.trim()) {
                case 'Lunes': return 1;
                case 'Martes': return 2;
                case 'Miércoles': return 3;
                case 'Jueves': return 4;
                case 'Viernes': return 5;
                case 'Sábado': return 6;
                case 'Domingo': return 0;
                default: return null;
            }
        }).filter(dia => dia !== null);

        let cantidadClases = 0;
        let fechaActual = parseISO(fechaInicio);
        const fechaFinObj = parseISO(fechaFin);

        while (!diasFrecuencia.includes(fechaActual.getDay()) && fechaActual <= fechaFinObj) {
            fechaActual = addDays(fechaActual, 1);
        }

        while (fechaActual <= fechaFinObj) {
            if (diasFrecuencia.includes(fechaActual.getDay())) {
                cantidadClases++;
            }
            fechaActual = addDays(fechaActual, 1);
        }
        return cantidadClases;
    };

    useEffect(() => {
        axiosInstance.get('/ListaGrupos')
            .then(response => setSelectedGrupos(response.data))
            .catch(error => console.error('Error fetching grupos:', error));
        axiosInstance.get('/ListaGrupos')
            .then(response => setPeriodoGrupos(response.data))
            .catch(error => console.error('Error fetching grupos:', error));
    }, []);

    const handleSearchChange = (e) => {
        searchGrupos(e.target.value);
    };

    const searchGrupos = (term) => {
        axiosInstance.get(`/ListaGruposBuscar`, {
            params: { grupo: `%${term}%` },
        })
            .then(response => setSelectedGrupos(response.data))
            .catch(error => console.error('Error fetching search results:', error));
    };

    const today = new Date();

    const gruposActivos = selectedGrupos.filter(grupo =>
        isWithinInterval(today, {
            start: parseISO(grupo.Fecha_Inicial),
            end: parseISO(grupo.Fecha_Final),
        })
    );

    const gruposNoActivos = selectedGrupos.filter(grupo =>
        isAfter(today, parseISO(grupo.Fecha_Final))
    );

    const renderTable = (grupos) => (
        <Table hover>
            <thead>
                <tr>
                    <th>Fecha Inicial</th>
                    <th>Fecha Final</th>
                    <th>Hora Inicial</th>
                    <th>Hora Final</th>
                    <th>Frecuencia</th>
                    <th>Periodo</th>
                    <th>Area</th>
                    <th>Oferta</th>
                    <th>Modulo</th>
                    <th>Maestro</th>
                    <th>Salon</th>
                    <th>Espacios</th>
                </tr>
            </thead>
            <tbody>
                {grupos.slice().reverse().map((grupo, index) => (
                    <tr key={index} onClick={() => handleShow(grupo)}>
                        <td>{formatDate(grupo.Fecha_Inicial)}</td>
                        <td>{formatDate(grupo.Fecha_Final)}</td>
                        <td>{grupo.Hora_Inicial}</td>
                        <td>{grupo.Hora_Final}</td>
                        <td>{grupo.Frecuencia}</td>
                        <td>{grupo.Periodo}</td>
                        <td>{grupo.Area}</td>
                        <td>{grupo.Oferta}</td>
                        <td>{grupo.Modulo}</td>
                        <td>{grupo.Maestro}</td>
                        <td>{grupo.Numero_Salon}</td>
                        <td>{grupo.SalonEspacios}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="container pt-4">
                <Card className="mb-3">
                    <Card.Body>
                        <h5 className="card-title">Lista de grupos</h5>
                        <p className="card-text">Buscar por periodo</p>
                        <Form.Select
                            name="grupo"
                            className="mb-3"
                            onChange={handleSearchChange}
                        >
                            <option value="">Seleccione un periodo</option>
                            {Array.from(new Set(periodoGrupos.map(periodo => periodo.Periodo)))
                                .map(uniquePeriodo => (
                                    <option key={uniquePeriodo} value={uniquePeriodo}>
                                        {uniquePeriodo}
                                    </option>
                                ))}
                        </Form.Select>

                        <button onClick={handleEdicion}>Crear</button>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header className="card-title">Grupos Activos</Card.Header>
                    <Card.Body>{renderTable(gruposActivos)}</Card.Body>
                </Card>

                <Card>
                    <Card.Header className="card-title">Grupos No Activos</Card.Header>
                    <Card.Body>{renderTable(gruposNoActivos)}</Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles del grupo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>Inicio del grupo:</strong> {formatDate(selectedRow.Fecha_Inicial) || 'No disponible'}</p>
                                <p><strong>Maestro:</strong> {selectedRow.Maestro || 'No disponible'}</p>
                                <p><strong>Area:</strong> {selectedRow.Area || 'No disponible'}</p>
                                <p><strong>Oferta:</strong> {selectedRow.Oferta || 'No disponible'}</p>
                                <p><strong>Modulo:</strong> {selectedRow.Modulo || 'No disponible'}</p>
                                <p><strong>Frecuencia:</strong> {selectedRow.Frecuencia || 'No disponible'}</p>
                                <p><strong>Cantidad de Clases:</strong> {calcularCantidadClases(selectedRow.Fecha_Inicial, selectedRow.Fecha_Final, selectedRow.Frecuencia) || 'No disponible'}</p>
                                <p><strong>Salon:</strong> {selectedRow.Numero_Salon || 'No disponible'}</p>
                                <p><strong>Espacios en el Salon:</strong> {selectedRow.SalonEspacios || 'No disponible'}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Link to={`/AddGrupos?id=${selectedRow?.GrupoID || 'Null'}`}>
                            <Button variant="primary">Editar</Button>
                        </Link>
                        <Link to={`/AGADD?id=${selectedRow?.GrupoID || 'Null'}`}>
                            <Button variant="danger">Alumnos</Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Grupos_Card;
