import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import InputText from '../components/input_text';
import ButtonSubmit from '../components/button_submit';
import axiosInstance from '../components/url_data';
import axios from 'axios';

import '../css/login.css';

const EncuestaCard = () => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const navigate = useNavigate();

    const handleEncuestas = () => {
        axiosInstance.post('/loginEncuesta', { code })
            .then((response) => {
                console.log(response);
                const id = response.data.valor;
                navigate(`/EncuestaLlenado?id=${id}`);
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Las credenciales no son válidas, intenta nuevamente.');
            });
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                        <h2 className="card-title text-center mb-3">Encuestas</h2>
                        <div className='row h-100 justify-content-center align-items-center' style={{ minHeight: '200px' }}>
                            <div className='col-md-9 text-center'>
                                <span>Codigo de encuesta:</span>
                                <InputText type="text" name="codes" id="codes" value={code} onChange={(e) => {
                                    setCode(e.target.value);
                                }} />
                            </div>
                            <button className="btn btn-outline-success mb-3" onClick={handleEncuestas}>
                                Confirmar
                            </button>
                            {errorMessage && <div className="col-md-12 text-center text-danger">{errorMessage}</div>}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default EncuestaCard;