// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-card {
    width: 20%;
    background-color: #608334;
    display: flex;
    flex-direction: column;
}

.navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navbar .nav-link {
    color: white;
    padding: 0.75rem 1rem;
    text-align: left;
    width: 100%;
    border: none;
    background: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    border-bottom: 1px solid white;
}

.navbar .nav-link:hover {
    background-color: #4a6a2a;
    transform: translateX(10px);
}

.navbar .nav-link:active {
    transform: translateX(0);
}
`, "",{"version":3,"sources":["webpack://./src/css/navbarCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,iDAAiD;IACjD,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".navbar-card {\n    width: 20%;\n    background-color: #608334;\n    display: flex;\n    flex-direction: column;\n}\n\n.navbar {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.navbar .nav-link {\n    color: white;\n    padding: 0.75rem 1rem;\n    text-align: left;\n    width: 100%;\n    border: none;\n    background: none;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s, transform 0.3s;\n    border-bottom: 1px solid white;\n}\n\n.navbar .nav-link:hover {\n    background-color: #4a6a2a;\n    transform: translateX(10px);\n}\n\n.navbar .nav-link:active {\n    transform: translateX(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
