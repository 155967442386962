import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdAreas_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [areaData, setAreaData] = useState({
        nombreArea: '',
        exists: 1,
        areaId: ''
    });

    const handleCrearAreas = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar area existente
            axiosInstance.post('/actualizarArea', areaData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nueva area
            axiosInstance.post('/crearArea', areaData)
                .then((response) => {
                    console.log(response);
                    navigate('/landingPage');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos del area si hay un ID
            axiosInstance.get(`/obtenerEdicionArea?id=${searchParam}`)
                .then(response => {
                    const areaArray = response.data;
                    console.log('Response from obtenerEdicionArea:', response);

                    if (Array.isArray(areaArray) && areaArray.length > 0) {
                        const area = areaArray[0]; // Acceder al primer objeto en el array

                        setAreaData({
                            nombreArea: area.Nombre,
                            exists: area.Existe,
                            areaId: area.ID
                        });

                        console.log('Updated areaData after API call:', {
                            nombreArea: area.Nombre,
                            exists: area.Existe,
                            areaId: area.ID
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo areas:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Areas</h5>
                        <p className="card-text">Ingresa el nombre del area</p>
                        <InputText
                            type="text"
                            name="name"
                            value={areaData.nombreArea}
                            onChange={(e) => setAreaData({ ...areaData, nombreArea: e.target.value })}
                        />
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearAreas}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdAreas_Card;
