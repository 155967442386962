import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Card, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';
import * as XLSX from 'xlsx';

const NewGeneral_Card = () => {

    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedGrupos, setSelectedGrupos] = useState([]);
    const [encuestasPromedios, setEncuestasPromedios] = useState([]);
    const [periodoGrupos, setPeriodoGrupos] = useState([]);
    const [encuestasData, setEncuestasData] = useState({
        Dominio: '',
        Horario: '',
        Trato: '',
        Dudas: '',
        Evaluacion: '',
        Objetivos: '',
        Practicas: '',
        Organizacion: '',
        Curso: '',
        Instalaciones: '',
        ServiciosCliente: '',
        Promedio: '',
        Contestados: '',
        Comentarios: ''
    });

    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        getInformacionEncuesta(row.ID);
        setShow(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : format(date, 'dd/MM/yyyy');
    };


    useEffect(() => {
        axiosInstance.get('/ListaEncuestas')
            .then(response => {
                setSelectedGrupos(response.data);
                setPeriodoGrupos(response.data);
            })
            .catch(error => {
                console.error('Error fetching encuestas:', error);
            });
        axiosInstance.get('/ListaPromedios')
            .then(response => {
                setEncuestasPromedios(response.data);
            })
            .catch(error => {
                console.error('Error fetching promedios:', error);
            });
    }, []);

    const getPromedioForGrupo = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        return promedio ? promedio.Promedio : 'N/A'; // Si no hay promedio, muestra 'N/A'
    };

    const getContestadosForGrupo = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        return promedio ? promedio.Contestados : 'N/A'; // Si no hay promedio, muestra 'N/A'
    };

    const getInformacionEncuesta = (ID) => {
        const promedio = encuestasPromedios.find(ep => ep.ID === ID);
        if (promedio) {
            setEncuestasData({
                Dominio: promedio.Dominio || '',
                Horario: promedio.Horario || '',
                Trato: promedio.Trato || '',
                Dudas: promedio.Dudas || '',
                Evaluacion: promedio.Evaluacion || '',
                Objetivos: promedio.Objetivos || '',
                Practicas: promedio.Practicas || '',
                Organizacion: promedio.Organizacion || '',
                Curso: promedio.Curso || '',
                Instalaciones: promedio.Instalaciones || '',
                ServiciosCliente: promedio.ServicioCliente || '',
                Promedio: promedio.Promedio || '',
                Contestados: promedio.Contestados || '',
                Comentarios: promedio.Comentarios || ''
            });
        }
    };

    const today = new Date();

    const formatHorarioCurso = (horario) => {
        // Asegúrate de que el horario no esté vacío o sea nulo
        if (!horario) return 'Horario no disponible';

        // Si el formato es como "14:00:00 - 17:00:00"
        const [inicio, fin] = horario.split(' - '); // Separar inicio y fin
        const formatoInicio = inicio.substring(0, 5); // Extraer "HH:mm"
        const formatoFin = fin.substring(0, 5); // Extraer "HH:mm"

        return `${formatoInicio} - ${formatoFin}`; // Retornar el formato deseado
    };

    const handleSearchChange = (e) => {
        searchGrupos(e.target.value);
    };
    const searchGrupos = (term) => {
        axiosInstance.get(`/ListaEncuestasBuscar`, {
            params: { salon: `%${term}%` } // Aquí usamos % para búsqueda parcial
        })
            .then(response => {
                setSelectedGrupos(response.data);
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet([encuestasData]); // Convierte el objeto de datos a una hoja de trabajo
        const wb = XLSX.utils.book_new(); // Crea un nuevo libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, "Encuesta"); // Añade la hoja de trabajo al libro
    
        // Descarga el archivo Excel con el nombre 'Encuesta.xlsx'
        XLSX.writeFile(wb, 'Encuesta.xlsx');
    };
    

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="card mb-3">
                    <Card.Body className="card-body">
                        <h5 className="card-title">Encuestas activas</h5>
                        <p className="card-text">Buscar por periodo</p>
                        <Form.Select
                            name="salon1"
                            className="mb-3"
                            onChange={handleSearchChange}
                        >
                            <option value="">Seleccione un periodo</option>
                            {Array.from(new Set(periodoGrupos.map(periodo => periodo.Periodo)))
                                .map(uniquePeriodo => (
                                    <option key={uniquePeriodo} value={uniquePeriodo}>
                                        {uniquePeriodo}
                                    </option>
                                ))}
                        </Form.Select>

                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Fecha de la encuesta</th>
                                    <th>Codigo</th>
                                    <th>Area</th>
                                    <th>Oferta</th>
                                    <th>Modulo</th>
                                    <th>Maestro</th>
                                    <th>Aula</th>
                                    <th>Dia</th>
                                    <th>Horario Curso</th>
                                    <th>Periodo</th>
                                    <th>Alumnos</th>
                                    <th>Contestados</th>
                                    <th>Promedio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedGrupos.filter(grupo => new Date(grupo.FechaFin) >= today).reverse().map((grupo, index) => (
                                    <tr key={index} onClick={() => handleShow(grupo)}>
                                        <td>{formatDate(grupo.FechaInicio)}</td>
                                        <td>{grupo.Codigo}</td>
                                        <td>{grupo.NombreArea}</td>
                                        <td>{grupo.NombreOferta}</td>
                                        <td>{grupo.NombreModulo}</td>
                                        <td>{grupo.NombreMaestro}</td>
                                        <td>{grupo.NombreSalon}</td>
                                        <td>{grupo.Frecuencia}</td>
                                        <td>{formatHorarioCurso(grupo.HorarioCurso)}</td>
                                        <td>{grupo.Periodo}</td>
                                        <td>{grupo.Cantidad}</td>
                                        <td>{getContestadosForGrupo(grupo.ID)}</td>
                                        <td>{getPromedioForGrupo(grupo.ID)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="card mb-3">
                    <Card.Body className="card-body">
                        <h5 className="card-title">Encuestas cerradas</h5>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Fecha de la encuesta</th>
                                    <th>Codigo</th>
                                    <th>Area</th>
                                    <th>Oferta</th>
                                    <th>Modulo</th>
                                    <th>Maestro</th>
                                    <th>Aula</th>
                                    <th>Dia</th>
                                    <th>Horario Curso</th>
                                    <th>Periodo</th>
                                    <th>Alumnos</th>
                                    <th>Contestados</th>
                                    <th>Promedio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedGrupos.filter(grupo => new Date(grupo.FechaFin) < today).reverse().map((grupo, index) => (
                                    <tr key={index} onClick={() => handleShow(grupo)}>
                                        <td>{formatDate(grupo.FechaInicio)}</td>
                                        <td>{grupo.Codigo}</td>
                                        <td>{grupo.NombreArea}</td>
                                        <td>{grupo.NombreOferta}</td>
                                        <td>{grupo.NombreModulo}</td>
                                        <td>{grupo.NombreMaestro}</td>
                                        <td>{grupo.NombreSalon}</td>
                                        <td>{grupo.Frecuencia}</td>
                                        <td>{formatHorarioCurso(grupo.HorarioCurso)}</td>
                                        <td>{grupo.Periodo}</td>
                                        <td>{grupo.Cantidad}</td>
                                        <td>{getContestadosForGrupo(grupo.ID)}</td>
                                        <td>{getPromedioForGrupo(grupo.ID) || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informacion de la encuesta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>Inicio de la encuesta:</strong> {formatDate(selectedRow.FechaInicio) || 'No disponible'}</p>
                                <p><strong>Maestro:</strong> {selectedRow.NombreMaestro || 'No disponible'}</p>
                                <p><strong>Dominio:</strong> {encuestasData.Dominio || 'No disponible'}</p>
                                <p><strong>Horario:</strong> {encuestasData.Horario || 'No disponible'}</p>
                                <p><strong>Trato:</strong> {encuestasData.Trato || 'No disponible'}</p>
                                <p><strong>Dudas:</strong> {encuestasData.Dudas || 'No disponible'}</p>
                                <p><strong>Evaluacion:</strong> {encuestasData.Evaluacion || 'No disponible'}</p>
                                <p><strong>Objetivos:</strong> {encuestasData.Objetivos || 'No disponible'}</p>
                                <p><strong>Practicas:</strong> {encuestasData.Practicas || 'No disponible'}</p>
                                <p><strong>Organizacion:</strong> {encuestasData.Organizacion || 'No disponible'}</p>
                                <p><strong>Curso:</strong> {encuestasData.Curso || 'No disponible'}</p>
                                <p><strong>Instalaciones:</strong> {encuestasData.Instalaciones || 'No disponible'}</p>
                                <p><strong>Servicios Cliente:</strong> {encuestasData.ServiciosCliente || 'No disponible'}</p>
                                <p><strong>Promedio:</strong> {encuestasData.Promedio || 'No disponible'}</p>
                                <p><strong>Contestados:</strong> {encuestasData.Contestados || 'No disponible'}</p>
                                <p><strong>Comentarios:</strong> {encuestasData.Comentarios || 'No disponible'}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={exportToExcel}>
                            Exportar
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default NewGeneral_Card;
