import React from 'react';
import NavbarCard from '../components/navbar_card';
import OfertasCard from '../components/ofertas_card';

const OfertasEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <OfertasCard />
            </div>
        </div>
    );
};

export default OfertasEditor;