import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import InputText from '../components/input_text';
import axios from 'axios';
import { format } from 'date-fns';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const EdGrupo_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [grupoData, setGrupoData] = useState({
        grupoId: '',
        areaId: '',
        ofertaId: '',
        moduloId: '',
        maestroId: '',
        salonId: '',
        modalidad: '',
        fechaInicio: '',
        fechaTerminacion: '',
        frecuencia: '',
        horaInicio: '',
        horaTerminacion: '',
        periodo: '',
        exists: 1,
    });

    const [ofertas, setOfertas] = useState([]);
    const [areas, setAreas] = useState([]);
    const [modulos, setModulos] = useState([]);
    const [maestros, setMaestros] = useState([]);
    const [salones, setSalones] = useState([]);

    const handleCrearGrupo = () => {
        const endpoint = searchParam && searchParam !== 'Null' ? 'actualizarGrupo' : 'crearGrupo';
        axiosInstance.post(`/${endpoint}`, grupoData)
            .then((response) => {
                console.log(response);
                navigate('/grupos');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerEdicionGrupo?id=${searchParam}`)
                .then(response => {
                    const ofertaArray = response.data;
                    if (Array.isArray(ofertaArray) && ofertaArray.length > 0) {
                        const oferta = ofertaArray[0];
                        console.log("Oferta data:", oferta);
                        const frecuenciaArray = oferta.Frecuencia ? oferta.Frecuencia.split(', ') : [];
                        setGrupoData({
                            grupoId: oferta.GrupoID,
                            areaId: oferta.AreaID,
                            ofertaId: oferta.OfertaID,
                            moduloId: oferta.ModuloID,
                            modalidad: oferta.Modalidad,
                            fechaInicio: oferta.Fecha_Inicial || '',
                            fechaTerminacion: oferta.Fecha_Final || '',
                            frecuencia: frecuenciaArray.join(', '),
                            periodo: oferta.Periodo,
                            horaInicio: oferta.Hora_Inicial || '',
                            horaTerminacion: oferta.Hora_Final || '',
                            maestroId: oferta.MaestroID || '',
                            salonId: oferta.SalonID || ''
                        });
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo grupo:', error);
                });
        }
    }, [searchParam]);

    useEffect(() => {
        axiosInstance.get('/ListaAreas')
            .then(response => {
                setAreas(response.data);
            })
            .catch(error => {
                console.error('Error fetching areas:', error);
            });
        axiosInstance.get('/ListaOfertas')
            .then(response => {
                setOfertas(response.data);
            })
            .catch(error => {
                console.error('Error fetching ofertas:', error);
            });
        axiosInstance.get('/ListaModulos')
            .then(response => {
                setModulos(response.data);
            })
            .catch(error => {
                console.error('Error fetching modulos:', error);
            });

        axiosInstance.get('/ListaMaestros')
            .then(response => {
                setMaestros(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });

        axiosInstance.get('/ListaSalones')
            .then(response => {
                setSalones(response.data);
            })
            .catch(error => {
                console.error('Error fetching salones:', error);
            });
    }, []);

    const handleFrecuenciaChange = (day) => {
        setGrupoData((prevState) => {
            const frecuenciaArray = prevState.frecuencia.split(', ');
            const newFrecuencia = frecuenciaArray.includes(day)
                ? frecuenciaArray.filter((d) => d !== day).join(', ')
                : [...frecuenciaArray, day].join(', ');
            return { ...prevState, frecuencia: newFrecuencia };
        });
    };

    const formatDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return isNaN(date) ? '' : format(date, 'yyyy-MM-dd');
        } catch (e) {
            console.error('Error formatting date:', e);
            return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/[^1-9]/g, ''); // Eliminar caracteres no numéricos
    
        // Validar que sea un número entre 100 y 1000
        if (numericValue >= 0 && numericValue <= 1000) {
            setGrupoData({ ...grupoData, [name]: numericValue });
        }
    };
    

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Grupos</h5>
                        <p className="card-text">Ingresa la area asignada</p>
                        <Form.Select
                            name="area"
                            className="mb-3"
                            value={grupoData.areaId}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    areaId: e.target.value
                                });
                            }}
                        >
                            <option value="">Seleccione una area</option>
                            {areas.map(area => (
                                <option key={area.ID} value={area.ID}>{area.Nombre}</option>
                            ))}
                        </Form.Select>
                        <p className="card-text">Ingresa la oferta asignada</p>
                        <Form.Select
                            name="oferta"
                            className="mb-3"
                            value={grupoData.ofertaId}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    ofertaId: e.target.value
                                });
                            }}
                        >
                            <option value="">Seleccione una oferta</option>
                            {ofertas.map(area => (
                                <option key={area.IdOferta} value={area.IdOferta}>{area.ofertaNombre}</option>
                            ))}
                        </Form.Select>
                        <p className="card-text">Ingresa el modulo asignado</p>
                        <Form.Select
                            name="modulo"
                            className="mb-3"
                            value={grupoData.moduloId}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    moduloId: e.target.value
                                });
                            }}
                        >
                            <option value="">Seleccione un modulo</option>
                            {modulos.map(area => (
                                <option key={area.IdModulo} value={area.IdModulo}>{area.moduloNombre}</option>
                            ))}
                        </Form.Select>

                        <p className="card-text">Ingresa la modalidad asignada</p>
                        <Form.Select
                            name="modalidad"
                            className="mb-3"
                            value={grupoData.modalidad}
                            onChange={(e) => {
                                setGrupoData({
                                    ...grupoData,
                                    modalidad: e.target.value
                                });
                            }}
                        >
                            <option value="">Seleccione una modalidad</option>
                            <option value="Presencial">Presencial</option>
                            <option value="En Linea">En Linea</option>
                        </Form.Select>

                        <p className="card-text">Fecha de Inicio</p>
                        <InputText
                            type="date"
                            name="fechaInicio"
                            value={formatDate(grupoData.fechaInicio)}
                            onChange={(e) => setGrupoData({ ...grupoData, fechaInicio: e.target.value })}
                        />
                        <p className="card-text">Fecha de Terminación</p>
                        <InputText
                            type="date"
                            name="fechaTerminacion"
                            value={formatDate(grupoData.fechaTerminacion)}
                            onChange={(e) => setGrupoData({ ...grupoData, fechaTerminacion: e.target.value })}
                        />
                        <p className="card-text">Frecuencia</p>
                        {['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'].map(day => (
                            <Form.Check
                                key={day}
                                type="checkbox"
                                label={day}
                                checked={grupoData.frecuencia.split(', ').includes(day)}
                                onChange={() => handleFrecuenciaChange(day)}
                            />
                        ))}
                        <p className="card-text">Hora de Inicio</p>
                        <InputText
                            type="time"
                            name="horaInicio"
                            value={grupoData.horaInicio}
                            onChange={(e) => setGrupoData({ ...grupoData, horaInicio: e.target.value })}
                        />
                        <p className="card-text">Hora de Terminación</p>
                        <InputText
                            type="time"
                            name="horaTerminacion"
                            value={grupoData.horaTerminacion}
                            onChange={(e) => setGrupoData({ ...grupoData, horaTerminacion: e.target.value })}
                        />
                        <p className="card-text">Periodo</p>
                        <InputText
                            type="text"
                            className="mb-3"
                            name="periodo"
                            value={grupoData.periodo}
                            onChange={handleInputChange}
                        />
                        <p className="card-text">Maestro</p>
                        <Form.Select
                            name="maestro"
                            className="mb-3"
                            value={grupoData.maestroId}
                            onChange={(e) => setGrupoData({ ...grupoData, maestroId: e.target.value })}
                        >
                            <option value="">Seleccione un maestro</option>
                            {maestros.map(maestro => (
                                <option key={maestro.MaestroID} value={maestro.MaestroID}>{maestro.MaestroNombre}</option>
                            ))}
                        </Form.Select>
                        <p className="card-text">Salón</p>
                        <Form.Select
                            name="salon"
                            className="mb-3"
                            value={grupoData.salonId}
                            onChange={(e) => setGrupoData({ ...grupoData, salonId: e.target.value })}
                        >
                            <option value="">Seleccione un salón</option>
                            {salones.map(salon => (
                                <option key={salon.ID} value={salon.ID}>{salon.Nombre}</option>
                            ))}
                        </Form.Select>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-success ml-2" onClick={handleCrearGrupo}>
                                {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdGrupo_Card;
