// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.detail_card
{
    background-color: #21212b;
    width: 100%;
    height: 100%;
    border: solid white 1px;
    border-radius: 25px;
    box-shadow: -10px 2px 1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    min-width: none;
    max-width: none;
}


.side-content-details
{ 
    background-color: #43636d;
    width: 100%;
    height: 100%;
    border: solid white 1px;
    border-radius: 25px;
    box-shadow: -10px 2px 1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.side-image
{
   width: 100%;
   height: auto;
   border-radius: 20px;
   max-height: 100%;
   min-height: 300px;
   max-width: 250px;
   min-width: 100px;
   

}

/*.basic-text
{
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
}*/

.cards 
{
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    overflow-y: auto;
    width: 100%;
    column-gap: 10px;
}

.card{
    background-color: #608334;
    border-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/css/generalCard.css"],"names":[],"mappings":";AACA;;IAEI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,4CAA4C;IAC5C,sBAAsB;IACtB,eAAe;IACf,eAAe;AACnB;;;AAGA;;IAEI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,4CAA4C;IAC5C,sBAAsB;AAC1B;;AAEA;;GAEG,WAAW;GACX,YAAY;GACZ,mBAAmB;GACnB,gBAAgB;GAChB,iBAAiB;GACjB,gBAAgB;GAChB,gBAAgB;;;AAGnB;;AAEA;;;;EAIE;;AAEF;;IAEI,aAAa;IACb,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":["\n.detail_card\n{\n    background-color: #21212b;\n    width: 100%;\n    height: 100%;\n    border: solid white 1px;\n    border-radius: 25px;\n    box-shadow: -10px 2px 1px rgba(0, 0, 0, 0.3);\n    box-sizing: border-box;\n    min-width: none;\n    max-width: none;\n}\n\n\n.side-content-details\n{ \n    background-color: #43636d;\n    width: 100%;\n    height: 100%;\n    border: solid white 1px;\n    border-radius: 25px;\n    box-shadow: -10px 2px 1px rgba(0, 0, 0, 0.3);\n    box-sizing: border-box;\n}\n\n.side-image\n{\n   width: 100%;\n   height: auto;\n   border-radius: 20px;\n   max-height: 100%;\n   min-height: 300px;\n   max-width: 250px;\n   min-width: 100px;\n   \n\n}\n\n/*.basic-text\n{\n    color: #ffffff;\n    font-family: Arial, Helvetica, sans-serif;\n}*/\n\n.cards \n{\n    display: flex;\n    flex-wrap: wrap;\n    height: 400px;\n    overflow-y: auto;\n    width: 100%;\n    column-gap: 10px;\n}\n\n.card{\n    background-color: #608334;\n    border-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
