import React from 'react';
import NavbarCard from '../components/navbar_card';
import GruposCard from '../components/grupos_card';

const GruposEditor = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <GruposCard />
            </div>
        </div>
    );
};

export default GruposEditor;