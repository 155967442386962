import React from 'react';
import NavbarCard from '../components/navbar_Admin';
import RevisorCard from '../components/revisor_card';

const RevisorAdmin = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <RevisorCard />
            </div>
        </div>
    );
};

export default RevisorAdmin;