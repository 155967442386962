import React from 'react';
import NavbarCard from '../components/navbar_card';
import EdiAreasCard from '../components/edicionAreas';

const AreasAdd = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <EdiAreasCard />
            </div>
        </div>
    );
};

export default AreasAdd;