import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const AlumnosGrupos_Card = () => {

    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");

    const [selectedGrupos, setSelectedGrupos] = useState([]);
    const [alumnos, setAlumnos] = useState([]);

    const [grupoAlumnosData, setGrupoAlumnosData] = useState({
        registroId: '',
        grupoId: searchParam,
        alumnosId: ''
    });

    const fetchGruposAlumnos = () => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerGrupoAlumno?id=${searchParam}`)
                .then(response => {
                    setSelectedGrupos(response.data);
                })
                .catch(error => {
                    console.error('Error obteniendo grupo:', error);
                });
        }
    };

    const handleAgregarAlumno = () => {
        axiosInstance.post(`/AgregarAlumno`, grupoAlumnosData)
            .then((response) => {
                console.log(response);
                fetchGruposAlumnos();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleEliminarAlumno = (grupoId, alumnoId) => {
        // Implement the logic for deleting a student here
        console.log(`Eliminar alumno con ID: ${alumnoId} del registro ${grupoId}`);
        axiosInstance.post(`/EliminarAlumno?idGrupo=${grupoId}&idAlumno=${alumnoId}`)
            .then((response) => {
                console.log(response);
                fetchGruposAlumnos();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            axiosInstance.get(`/obtenerGrupoAlumno?id=${searchParam}`)
                .then(response => {
                    setSelectedGrupos(response.data);
                })
                .catch(error => {
                    console.error('Error obteniendo grupo:', error);
                });
        }
    }, [searchParam]);

    useEffect(() => {
        axiosInstance.get('/ListaAlumnos')
            .then(response => {
                setAlumnos(response.data);
            })
            .catch(error => {
                console.error('Error fetching alumnos:', error);
            });
    }, []);

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                        <span>Alumnos a agregar al grupo:</span>
                        <Form.Select
                            name="alumnosInput"
                            className="mb-3"
                            value={grupoAlumnosData.alumnosId}
                            onChange={(e) => setGrupoAlumnosData({ ...grupoAlumnosData, alumnosId: e.target.value })}
                        >
                            <option value="">Seleccione un alumno</option>
                            {alumnos.map(alumno => (
                                <option key={alumno.ID} value={alumno.ID}>{alumno.Nombre}</option>
                            ))}
                        </Form.Select>
                        <button onClick={handleAgregarAlumno}>Agregar</button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>ID grupo</th>
                                    <th>Nombre</th>
                                    <th>Matricula</th>
                                    <th>Existe</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedGrupos.map((grupo, index) => (
                                    <tr key={index}>
                                        <td>{grupo && grupo.ID_Grupo ? grupo.ID_Grupo : 'Null'}</td>
                                        <td>{grupo.alumnoNombre}</td>
                                        <td>{grupo.Matricula}</td>
                                        <td>{grupo.Alumno_Existe}</td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleEliminarAlumno(grupo.ID_Grupo, grupo.ID_Alumno)}
                                            >
                                                Eliminar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default AlumnosGrupos_Card;
