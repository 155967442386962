import React from 'react';
import LoginCard from '../components/login_card';

const Login = () => {
    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <div className="flex-grow-1">
                <LoginCard />
            </div>
        </div>

    );
};

export default Login