import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/navbar.css';

const NavbarAllCard = () => {
    const [userName, setUsername] = useState('');
    const [initialRedirectDone, setInitialRedirectDone] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        // Eliminar datos del localStorage
        localStorage.clear();
        // Redirigir a otra p�gina
        navigate('/login');
    };

    useEffect(() => {
        // Recuperar el nombre del usuario del localStorage
        if (!initialRedirectDone) {
            const rol = localStorage.getItem('Rol');

            if (rol != null) {
                if (rol === '1') {
                    navigate('/landingPageAdmin');
                } else if (rol === '2') {
                    navigate('/landingPage');
                } else {
                    console.log("Credenciales Correctas");
                }
            } else {
                navigate('/login');
            }

            // Establece que la redirección inicial ya se realizó
            setInitialRedirectDone(true);
        }

        const storedUsername = localStorage.getItem('userData');
        const storedUsername2 = storedUsername ? JSON.parse(storedUsername) : null;
        if (storedUsername2) {
            setUsername(storedUsername2);
        }
    }, [initialRedirectDone, navigate]);

    return (
        <div style={{ width: '100%' }}>
            <nav className="navbar navbar-expand-lg" style={{ height: '150px', backgroundColor: '#608334' }}>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <label className="nav-link text-white">{userName}</label>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link text-white" onClick={handleLogout}>Salir de la sesion</button>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default NavbarAllCard;
