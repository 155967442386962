import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../components/input_text';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const EdSalones_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [salonData, setSalonData] = useState({
        numero: '',
        exists: 1,
        salonId: '',
        espacios: '',
    });

    // Nuevo estado para manejar el checkbox
    const [isMSChecked, setIsMSChecked] = useState(false);

    const handleCrearSalones = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar maestro existente
            axiosInstance.post('/actualizarSalon', salonData)
                .then((response) => {
                    console.log(response);
                    navigate('/salones');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nuevo maestro
            axiosInstance.post('/crearSalon', salonData)
                .then((response) => {
                    console.log(response);
                    navigate('/salones');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos del maestro si hay un ID
            axiosInstance.get(`/obtenerEdicionSalon?id=${searchParam}`)
                .then(response => {
                    const salonArray = response.data;
                    console.log('Response from obtenerEdicionSalon:', response);

                    if (Array.isArray(salonArray) && salonArray.length > 0) {
                        const salon = salonArray[0]; // Acceder al primer objeto en el array

                        setSalonData({
                            numero: salon.Nombre,
                            exists: salon.Existe,
                            espacios: salon.Espacios,
                            salonId: salon.ID
                        });

                        console.log('Updated salonData after API call:', {
                            numero: salon.Nombre,
                            exists: salon.Existe,
                            espacios: salon.Espacios,
                            salonId: salon.ID
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo salones:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        if (numericValue === '' || parseInt(numericValue, 10) >= 1) {
            setSalonData({ ...salonData, numero: numericValue });
        }
    };

    const handleEspaciosChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        if (numericValue === '' || parseInt(numericValue, 10) >= 1) {
            setSalonData({ ...salonData, espacios: numericValue });
        }
    };

    // Función para manejar el cambio del checkbox
    const handleCheckboxChange = () => {
        setIsMSChecked(!isMSChecked);
        if (!isMSChecked) {
            setSalonData({ ...salonData, numero: "MS Teams" }); // Establece el valor como "MS Teams" cuando se marca el checkbox
        } else {
            setSalonData({ ...salonData, numero: '' }); // Restablece el valor cuando se desmarca
        }
    };    
    

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Salones</h5>
                        <p className="card-text">Ingresa el numero del salon</p>
                        
                        {/* Checkbox para deshabilitar el campo de texto */}
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="msTeamsCheckbox"
                                checked={isMSChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label" htmlFor="msTeamsCheckbox">
                                Usar MS Teams
                            </label>
                        </div>

                        <InputText
                            type="text"
                            name="numero"
                            value={salonData.numero}
                            onChange={handleInputChange}
                            disabled={isMSChecked} // Deshabilitar cuando el checkbox esté marcado
                        />

                        <p className="card-text">Ingresa el numero de espacios del salon</p>
                        <InputText
                            type="text"
                            name="espacios"
                            value={salonData.espacios}
                            onChange={handleEspaciosChange}
                        />

                        <button className="btn btn-outline-success ml-2" onClick={handleCrearSalones}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdSalones_Card;
