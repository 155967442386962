import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Table } from 'react-bootstrap';
import InputText from '../components/input_text';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Salones_Card = () => {

    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSalones, setSelectedSalones] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleEdicion = () => {
        navigate('/AddSalones');
    };

    useEffect(() => {
        axiosInstance.get('/ListaSalones')
            .then(response => {
                setSelectedSalones(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        searchSalones(e.target.value);
    };
    const searchSalones = (term) => {
        axiosInstance.get(`/ListaSalonesBuscar`, {
            params: { salon: `%${term}%` } // Aquí usamos % para búsqueda parcial
        })
            .then(response => {
                setSelectedSalones(response.data);
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                        <h5 className="card-title">Lista de salones</h5>
                        <p className="card-text">Buscar por salon</p>
                        <InputText
                            type="text"
                            name="salon"
                            onChange={handleSearchChange}
                        />
                        <button onClick={handleEdicion}>Crear</button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Salon</th>
                                    <th>Espacios</th>
                                    <th>Existe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSalones.slice().reverse().map((salon, index) => (
                                    <tr key={index} onClick={() => handleShow(salon)}>
                                        <td>{salon.Nombre}</td>
                                        <td>{salon.Espacios}</td>
                                        <td>{salon.Existe}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles del Salon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>ID del salon:</strong> {selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}</p>
                                <p><strong>Numero de salon:</strong> {selectedRow.Nombre}</p>
                                <p><strong>Numero de espacios:</strong> {selectedRow.Espacios}</p>
                                <p><strong>Existe:</strong> {selectedRow.Existe}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Link to={`/AddSalones?id=${selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}`}>
                            <Button variant="primary">
                                Editar
                            </Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Salones_Card;
