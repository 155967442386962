import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import InputText from '../components/input_text';
import axios from 'axios';
import '../css/generalCard.css';
import axiosInstance from '../components/url_data';

const EdOfertas_Card = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();

    const [ofertaData, setOfertaData] = useState({
        nameOferta: '',
        nameArea: '',
        modalidad: '',
        exists: 1,
        ofertaId: '',
        areaId: ''
    });

    const [selectedAreas, setSelectedAreas] = useState([]);

    const handleCrearOfertas = () => {
        if (searchParam && searchParam !== 'Null') {
            // Actualizar maestro existente
            axiosInstance.post('/actualizarOferta', ofertaData)
                .then((response) => {
                    console.log(response);
                    navigate('/ofertas');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Crear nuevo maestro
            axiosInstance.post('/crearOferta', ofertaData)
                .then((response) => {
                    console.log(response);
                    navigate('/ofertas');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        if (searchParam && searchParam !== 'Null') {
            // Obtener los datos de la oferta si hay un ID
            axiosInstance.get(`/obtenerEdicionOfertas?id=${searchParam}`)
                .then(response => {
                    const ofertaArray = response.data;
                    console.log('Response from obtenerEdicionOfertas:', response);

                    if (Array.isArray(ofertaArray) && ofertaArray.length > 0) {
                        const oferta = ofertaArray[0]; // Acceder al primer objeto en el array

                        setOfertaData({
                            nameOferta: oferta.ofertaNombre,
                            nameArea: oferta.areaNombre,
                            modalidad: oferta.Modalidad,
                            exists: oferta.ofertaExiste,
                            ofertaId: oferta.IdOferta,
                            areaId: oferta.IdArea
                        });

                        console.log('Updated ofertaData after API call:', {
                            nameOferta: oferta.ofertaNombre,
                            nameArea: oferta.areaNombre,
                            modalidad: oferta.Modalidad,
                            exists: oferta.ofertaExiste,
                            ofertaId: oferta.IdOferta,
                            areaId: oferta.IdArea
                        });
                    } else {
                        console.error('Response data is not an array or is empty:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo oferta:', error);
                });
        } else {
            console.log('No valid searchParam found:', searchParam);
        }
    }, [searchParam]);

    useEffect(() => {
        axiosInstance.get('/ListaAreas')
            .then(response => {
                setSelectedAreas(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);



    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Ofertas</h5>
                        <p className="card-text">Ingresa el nombre de la oferta</p>
                        <InputText
                            type="text"
                            name="oferta"
                            value={ofertaData.nameOferta}
                            onChange={(e) => setOfertaData({ ...ofertaData, nameOferta: e.target.value })}
                        />
                        <p className="card-text">Ingresa la area asignada</p>
                        <Form.Select
                            name="area"
                            className="mb-3"
                            value={ofertaData.areaId}
                            onChange={(e) => {
                                const selectedArea = selectedAreas.find(area => area.ID === parseInt(e.target.value));
                                console.log('Cambio en combobox área:', e.target.value, selectedArea);
                                setOfertaData({ 
                                    ...ofertaData, 
                                    areaId: e.target.value, 
                                    nameArea: selectedArea ? selectedArea.Nombre : '' 
                                });
                            }}
                        >
                            <option value="">Seleccione un área</option>
                            {selectedAreas.map(area => (
                                <option key={area.ID} value={area.ID}>{area.Nombre}</option>
                            ))}
                        </Form.Select>
                        <p className="card-text">Ingresa la modalidad</p>
                        <Form.Select
                            name="modalidad"
                            className="mb-3"
                            value={ofertaData.modalidad}
                            onChange={(e) => {
                                const selectedModalidad = e.target.value;
                                console.log('Cambio en combobox modalidad:', e.target.value, selectedModalidad);
                                setOfertaData({ 
                                    ...ofertaData, 
                                    modalidad: e.target.value
                                });
                            }}
                        >
                            <option value="Presencial">Presencial</option>
                            <option value="Linea">Linea</option>
                            <option value="Hibrido">Hibrido</option>
                        </Form.Select>
                        <button className="btn btn-outline-success ml-2" onClick={handleCrearOfertas}>
                            {searchParam && searchParam !== 'Null' ? 'Actualizar' : 'Confirmar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdOfertas_Card;
