import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Table } from 'react-bootstrap';
import InputText from '../components/input_text';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Alumnos_Card = () => {

    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedAlumnos, setSelectedAlumnos] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleEdicion = () => {
        navigate('/AddAlumnos');
    };

    useEffect(() => {
        axiosInstance.get('/ListaAlumnos')
            .then(response => {
                setSelectedAlumnos(response.data);
            })
            .catch(error => {
                console.error('Error fetching maestros:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        searchAlumnos(e.target.value);
    };
    const searchAlumnos = (term) => {
        axiosInstance.get(`/ListaAlumnosBuscar`, {
            params: { alumno: `%${term}%` } // Aquí usamos % para búsqueda parcial
        })
            .then(response => {
                setSelectedAlumnos(response.data);
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                <Card className="mb-3">
                    <Card.Body>
                        <h5 className="card-title">Lista de alumnos</h5>
                        <p className="card-text">Buscar por alumno</p>
                        <InputText
                            type="text"
                            name="alumno"
                            onChange={handleSearchChange}
                        />
                        <button onClick={handleEdicion}>Crear</button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>matricula</th>
                                    <th>Telefono</th>
                                    <th>Numero</th>
                                    <th>Existe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedAlumnos.slice().reverse().map((alumno, index) => (
                                    <tr key={index} onClick={() => handleShow(alumno)}>
                                        <td>{alumno.Nombre}</td>
                                        <td>{alumno.Matricula}</td>
                                        <td>{alumno.Numero}</td>
                                        <td>{alumno.Correo}</td>
                                        <td>{alumno.Existe}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles del Maestro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>ID del alumno:</strong> {selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}</p>
                                <p><strong>Nombre completo:</strong> {selectedRow.Nombre}</p>
                                <p><strong>Matricula completa:</strong> {selectedRow.Matricula}</p>
                                <p><strong>Numero completo:</strong> {selectedRow.Numero}</p>
                                <p><strong>Correo completo:</strong> {selectedRow.Correo}</p>
                                <p><strong>Existe:</strong> {selectedRow.Existe}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Link to={`/AddAlumnos?id=${selectedRow && selectedRow.ID ? selectedRow.ID : 'Null'}`}>
                            <Button variant="primary">
                                Editar
                            </Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Alumnos_Card;
