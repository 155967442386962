import React, { useEffect, useState } from 'react';
import { Form, Card, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { parseISO, addDays, format } from 'date-fns';
import axiosInstance from '../components/url_data';
import '../css/generalCard.css';

const Minutas_Card = () => {
    const [alumnoData, setAlumnoData] = useState({
        ID: '',
        AlumnoID: '',
        GrupoID: '',
        asistenciaClase1: '',
        asistenciaClase2: '',
        asistenciaClase3: '',
        asistenciaClase4: '',
        practica1Clase1: '',
        practica2Clase1: '',
        practica3Clase1: '',
        practica4Clase1: '',
        practica5Clase1: '',
        practica1Clase2: '',
        practica2Clase2: '',
        practica3Clase2: '',
        practica4Clase2: '',
        practica5Clase2: '',
        practica1Clase3: '',
        practica2Clase3: '',
        practica3Clase3: '',
        practica4Clase3: '',
        practica5Clase3: '',
        practica1Clase4: '',
        practica2Clase4: '',
        practica3Clase4: '',
        practica4Clase4: '',
        practica5Clase4: '',
        Tarea1: '',
        Tarea2: '',
        Tarea3: '',
        Proyecto: '',
        Examen: '',
        Participacion: '',
        Comentarios: '',
        claseSeleccionada: '',
        CalificacionFinal: '',
    });

    const defaultAlumnoData = {
        ID: '',
        AlumnoID: '',
        GrupoID: '',
        asistenciaClase1: '',
        asistenciaClase2: '',
        asistenciaClase3: '',
        asistenciaClase4: '',
        practica1Clase1: '',
        practica2Clase1: '',
        practica3Clase1: '',
        practica4Clase1: '',
        practica5Clase1: '',
        practica1Clase2: '',
        practica2Clase2: '',
        practica3Clase2: '',
        practica4Clase2: '',
        practica5Clase2: '',
        practica1Clase3: '',
        practica2Clase3: '',
        practica3Clase3: '',
        practica4Clase3: '',
        practica5Clase3: '',
        practica1Clase4: '',
        practica2Clase4: '',
        practica3Clase4: '',
        practica4Clase4: '',
        practica5Clase4: '',
        Tarea1: '',
        Tarea2: '',
        Tarea3: '',
        Proyecto: '',
        Examen: '',
        Participacion: '',
        Comentarios: '',
        CalificacionFinal: '',
    };

    const [alumnos, setAlumnos] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [userIdLocal, setUserIdLocal] = useState('');
    const [userId, setUserId] = useState('');
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [grupoActual, setGrupoActual] = useState("");
    const [mostrarCamposAlumno, setMostrarCamposAlumno] = useState(false);
    const [error, setError] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        const storedUseriD = localStorage.getItem('userId');
        const storedUseriD2 = storedUseriD ? JSON.parse(storedUseriD) : null;
        if (storedUseriD2) {
            setUserIdLocal(storedUseriD2);
        }
    }, []);

    useEffect(() => {
        if (userIdLocal) {
            axiosInstance.get(`/obtenerIdMaestro?id=${userIdLocal}`)
                .then(response => {
                    const mID = response.data[0].MaestroID;
                    setUserId(mID);
                })
                .catch(error => {
                    console.error('Error fetching grupos:', error);
                });
        }
    }, [userIdLocal]);

    useEffect(() => {
        if (userId) {
            axiosInstance.get(`/ListaGruposFiltros?maestroId=${userId}`)
                .then(response => {
                    setGrupos(response.data);
                })
                .catch(error => {
                    console.error('Error fetching grupos:', error);
                });
        }
    }, [userId]);

    const handleGrupoChange = (e) => {
        const grupoId = e.target.value;
        setGrupoActual(grupoId);
        if (grupoId === "") {
            setGrupoSeleccionado(null); // Limpiar la tabla si se selecciona la opción vacía
            setAlumnos([]);
            setMostrarCamposAlumno(false);
            setAlumnoData(defaultAlumnoData);
            setError('');
        } else {
            const grupo = grupos.find(grupo => grupo.GrupoID === parseInt(grupoId));
            setGrupoSeleccionado(grupo); // Actualizar con los datos del grupo seleccionado
            setAlumnos([]);
            setMostrarCamposAlumno(false);
            setAlumnoData(defaultAlumnoData);
            setError('');
            

            axiosInstance.get(`/obtenerGrupoAlumno?id=${grupoId}`)
                .then(response => {
                    setAlumnos(response.data);
                })
                .catch(error => {
                    console.error('Error fetching alumnos:', error);
                });
        }
    };

    const formatDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd/MM/yyyy') : 'N/A';
    };

    const calcularCantidadClases = (fechaInicio, fechaFin, frecuencia) => {
        const diasFrecuencia = frecuencia.split(',').map(dia => {
            switch (dia.trim()) {
                case 'Lunes': return 1;
                case 'Martes': return 2;
                case 'Miércoles': return 3;
                case 'Jueves': return 4;
                case 'Viernes': return 5;
                case 'Sábado': return 6;
                case 'Domingo': return 0;
                default: return null;
            }
        }).filter(dia => dia !== null);
    
        let cantidadClases = 0;
        let fechaActual = parseISO(fechaInicio);
        const fechaFinObj = parseISO(fechaFin);
    
        // Avanza al primer día que coincida con la frecuencia indicada
        while (!diasFrecuencia.includes(fechaActual.getDay()) && fechaActual <= fechaFinObj) {
            fechaActual = addDays(fechaActual, 1);
        }
    
        // Cuenta las clases en los días de frecuencia hasta la fecha final
        while (fechaActual <= fechaFinObj) {
            if (diasFrecuencia.includes(fechaActual.getDay())) {
                cantidadClases++;
            }
            fechaActual = addDays(fechaActual, 1); // Avanza un día a la vez
        }
    
        return cantidadClases;
    };

    const handleAlumnoChange = (e) => {
        const alumnoId = e.target.value;
        setAlumnoData(defaultAlumnoData);
        if (alumnoId === "") {
            setMostrarCamposAlumno(false); // Ocultar campos si se selecciona la opción vacía
            setIsUpdate(false);
        } else {
            setMostrarCamposAlumno(true); // Mostrar campos si se selecciona un alumno

            axiosInstance.get(`/obtenerInformacionAlumno?id=${alumnoId}&grupoId=${grupoActual}`)
                .then(response => {
                    const alumnoArray = response.data;
                    if (Array.isArray(alumnoArray) && alumnoArray.length > 0) {
                        const alumno = alumnoArray[0];
                        setAlumnoData({
                            ID: alumno.ID,
                            AlumnoID: alumno.IDAlumno,
                            GrupoID: alumno.IDGrupo,
                            asistenciaClase1: alumno.AsistenciaUno,
                            asistenciaClase2: alumno.AsistenciaDos,
                            asistenciaClase3: alumno.AsistenciaTres,
                            asistenciaClase4: alumno.AsistenciaCuatro,
                            practica1Clase1: alumno.Practica1,
                            practica2Clase1: alumno.Practica2,
                            practica3Clase1: alumno.Practica3,
                            practica4Clase1: alumno.Practica4,
                            practica5Clase1: alumno.Practica5,
                            practica1Clase2: alumno.Practica6,
                            practica2Clase2: alumno.Practica7,
                            practica3Clase2: alumno.Practica8,
                            practica4Clase2: alumno.Practica9,
                            practica5Clase2: alumno.Practica10,
                            practica1Clase3: alumno.Practica11,
                            practica2Clase3: alumno.Practica12,
                            practica3Clase3: alumno.Practica13,
                            practica4Clase3: alumno.Practica14,
                            practica5Clase3: alumno.Practica15,
                            practica1Clase4: alumno.Practica16,
                            practica2Clase4: alumno.Practica17,
                            practica3Clase4: alumno.Practica18,
                            practica4Clase4: alumno.Practica19,
                            practica5Clase4: alumno.Practica20,
                            Tarea1: alumno.Tarea1,
                            Tarea2: alumno.Tarea2,
                            Tarea3: alumno.Tarea3,
                            Proyecto: alumno.Proyecto,
                            Examen: alumno.Examen,
                            Participacion: alumno.Participacion,
                            Comentarios: alumno.Comentarios,
                            CalificacionFinal: alumno.CalificacionFinal,
                        });
                        setIsUpdate(alumno.ID !== "NULL" && alumno.ID !== null && alumno.ID !== undefined);
                    } else {
                        setIsUpdate(false);
                    }
                })
                .catch(error => {
                    console.error('Error obteniendo alumnos:', error);
                });
        }
    };

    const handleClaseChange = (e) => {
        const claseSeleccionada = e.target.value;
        setAlumnoData({
            ...alumnoData,
            claseSeleccionada: claseSeleccionada
        });
    };

    const handleGuardarCalificacion = () => {
        const { ID, AlumnoID, GrupoID, Proyecto, Examen, Participacion, Comentarios } = alumnoData;

        const calificacionFinal = calcularCalificacionFinal(alumnoData);

        // Validamos que todas las clases tengan asistencia y calificaciones completas
        for (let i = 1; i <= 4; i++) {
            if (
                !alumnoData[`asistenciaClase${i}`] ||
                !alumnoData[`practica1Clase${i}`] ||
                !alumnoData[`practica2Clase${i}`] ||
                !alumnoData[`practica3Clase${i}`] ||
                !alumnoData[`practica4Clase${i}`] ||
                !alumnoData[`practica5Clase${i}`] ||
                (i < 4 && !alumnoData[`Tarea${i}`])
            ) {
                setError(`Faltan campos por llenar en la Clase ${i}.`);
                return;
            }
        }

        if (!Proyecto || !Examen || !Participacion || !Comentarios) {
            setError('Por favor, asegúrate de que todos los campos del proyecto, examen, participación y comentarios estén llenos.');
            return;
        }

        if (!AlumnoID || !GrupoID) {
            setError('Por favor, selecciona un grupo y un alumno.');
            console.log('Datos AlumnoID y Grupo ID:', AlumnoID + GrupoID);
            return;
        }

        // Si todo está completo, limpiamos el mensaje de error
        setError('');

        // Crear objeto con la información completa del alumno
        const datosCompletosAlumno = {
            ID,
            AlumnoID,
            GrupoID,
            Proyecto,
            Examen,
            Participacion,
            Comentarios,
            CalificacionFinal: calificacionFinal,
            asistenciaUno: alumnoData.asistenciaClase1,
            practicaUno: alumnoData.practica1Clase1,
            practicaDos: alumnoData.practica2Clase1,
            practicaTres: alumnoData.practica3Clase1,
            practicaCuatro: alumnoData.practica4Clase1,
            practicaCinco: alumnoData.practica5Clase1,
            tareaUno: alumnoData.Tarea1,
            asistenciaDos: alumnoData.asistenciaClase2,
            practicaSeis: alumnoData.practica1Clase2,
            practicaSiete: alumnoData.practica2Clase2,
            practicaOcho: alumnoData.practica3Clase2,
            practicaNueve: alumnoData.practica4Clase2,
            practicaDiez: alumnoData.practica5Clase2,
            tareaDos: alumnoData.Tarea2,
            asistenciaTres: alumnoData.asistenciaClase3,
            practicaOnce: alumnoData.practica1Clase3,
            practicaDoce: alumnoData.practica2Clase3,
            practicaTrece: alumnoData.practica3Clase3,
            practicaCatorce: alumnoData.practica4Clase3,
            practicaQuince: alumnoData.practica5Clase3,
            tareaTres: alumnoData.Tarea3,
            asistenciaCuatro: alumnoData.asistenciaClase4,
            practicaDieciseis: alumnoData.practica1Clase4,
            practicaDiecisiete: alumnoData.practica2Clase4,
            practicaDieciocho: alumnoData.practica3Clase4,
            practicaDiecinueve: alumnoData.practica4Clase4,
            practicaVeinte: alumnoData.practica5Clase4
        };

        // Aquí puedes realizar la lógica para guardar los datos en la base de datos usando axios u otro método
        console.log('Datos completos del alumno:', datosCompletosAlumno);

        const endpoint = isUpdate ? 'actualizarMinuta' : 'crearMinuta';
        axiosInstance.post(`/${endpoint}`, datosCompletosAlumno)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error(error);
            });



        setIsUpdate(true);
    };

    const calcularCalificacionFinal = (alumnoData) => {
        let PE = 0, PC = 0, PD = 0, PN = 0;
        let TE = 0, TC = 0, TD = 0, TN = 0;
        let calificacionPracticas = 0, calificacionTareas = 0;
        let calificacionParticipacion = 0, calificacionProyecto = 0, calificacionExamen = 0;

        // Calcular Practicas
        for (let i = 1; i <= 4; i++) {
            for (let j = 1; j <= 5; j++) {
                const practica = alumnoData[`practica${j}Clase${i}`];
                if (practica === 'E') PE++;
                else if (practica === 'C') PC++;
                else if (practica === 'D') PD++;
                else if (practica === 'N') PN++;
            }
        }
        const sumaPracticas = PE + PC + PD + PN;
        calificacionPracticas = (50 / sumaPracticas) * (PE + PC * 0.6 + PD * 0.3);

        // Calcular Proyecto
        const proyecto = alumnoData.Proyecto;
        calificacionProyecto = proyecto === 'E' ? 20 : proyecto === 'C' ? 20 * 0.6 : proyecto === 'D' ? 20 * 0.3 : 0;

        // Calcular Examen (Valor numérico)
        calificacionExamen = alumnoData.Examen * 0.1;

        // Calcular Tareas
        for (let i = 1; i <= 3; i++) {
            const tarea = alumnoData[`Tarea${i}`];
            if (tarea === 'E') TE++;
            else if (tarea === 'C') TC++;
            else if (tarea === 'D') TD++;
            else if (tarea === 'N') TN++;
        }
        const valorClases = 4; // Asumiendo que valor_clases es 4
        calificacionTareas = (10 / (valorClases - 1)) * (TE + TC * 0.6 + TD * 0.3);

        // Calcular Participación
        const participacion = alumnoData.Participacion;
        calificacionParticipacion = participacion === 'E' ? 10 : participacion === 'C' ? 10 * 0.6 : participacion === 'D' ? 10 * 0.3 : 0;

        // Calificación Final
        const calificacionFinal = calificacionPracticas + calificacionProyecto + calificacionExamen + calificacionTareas + calificacionParticipacion;
        return calificacionFinal;
    };


    // Renderizar campos adicionales dependiendo del número de la clase seleccionada
    const renderCampoClaseSeleccionada = () => {
        if (!alumnoData.claseSeleccionada) {
            return null;
        }

        const claseNumero = parseInt(alumnoData.claseSeleccionada.replace('class', ''));
        const asistencia = alumnoData[`asistenciaClase${claseNumero}`];
        const isSelect1Filled = !!alumnoData[`asistenciaClase${claseNumero}`];

        const getOptionsForPractica = () => {
            switch (asistencia) {
                case 'Asistencia':
                    return ['E', 'C', 'D', 'N'];
                case 'Retardo':
                    return ['C', 'D', 'N'];
                case 'Falta':
                    return ['C'];
                case 'Justificante':
                    return ['C', 'D', 'N'];
                default:
                    return [];
            }
        };

        const getOptionsForTarea = () => {
            switch (asistencia) {
                case 'Asistencia':
                    return ['E', 'C', 'D', 'N'];
                case 'Retardo':
                    return ['E', 'C', 'D', 'N'];
                case 'Falta':
                    return ['C', 'D', 'N'];
                case 'Justificante':
                    return ['C', 'D', 'N'];
                default:
                    return [];
            }
        };

        return (
            <div>
                <Form.Group className="mb-3">
                    <Form.Label>Asistencia para Clase {claseNumero}</Form.Label>
                    <Form.Select
                        name={`asistenciaClase${claseNumero}`}
                        className="mb-3"
                        value={alumnoData[`asistenciaClase${claseNumero}`] || ''}
                        onChange={(e) =>
                            setAlumnoData({
                                ...alumnoData,
                                [`asistenciaClase${claseNumero}`]: e.target.value
                            })
                        }
                    >
                        <option value="">Seleccione la asistencia para Clase {claseNumero}</option>
                        <option value="Asistencia">Asistencia</option>
                        <option value="Retardo">Retardo</option>
                        <option value="Falta">Falta</option>
                        <option value="Justificante">Justificante</option>
                    </Form.Select>
                </Form.Group>

                {[...Array(5)].map((_, i) => (
                    <Form.Group className="mb-3" key={`practica-${i}`}>
                        <Form.Label>Practica {i + 1} para Clase {claseNumero}</Form.Label>
                        <Form.Select
                            name={`practica${i + 1}Clase${claseNumero}`}
                            className="mb-3"
                            value={alumnoData[`practica${i + 1}Clase${claseNumero}`] || ''}
                            onChange={(e) =>
                                setAlumnoData({
                                    ...alumnoData,
                                    [`practica${i + 1}Clase${claseNumero}`]: e.target.value
                                })
                            }
                            disabled={!isSelect1Filled}
                        >
                            <option value="">Seleccione la calificación de la practica {i + 1} para Clase {claseNumero}</option>
                            {getOptionsForPractica().map((optionValue) => (
                                <option key={optionValue} value={optionValue}>{optionValue}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                ))}


                {claseNumero <= 3 && (
                    <Form.Group className="mb-3">
                        <Form.Label>Tarea para Clase {claseNumero}</Form.Label>
                        <Form.Select
                            name={`Tarea${claseNumero}`}
                            className="mb-3"
                            value={alumnoData[`Tarea${claseNumero}`] || ''}
                            onChange={(e) =>
                                setAlumnoData({
                                    ...alumnoData,
                                    [`Tarea${claseNumero}`]: e.target.value
                                })
                            }
                            disabled={!isSelect1Filled}
                        >
                            <option value="">Seleccione la calificación de la tarea para la clase {claseNumero}</option>
                            {getOptionsForTarea().map((optionValue) => (
                                <option key={optionValue} value={optionValue}>{optionValue}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
            </div>
        );
    };

    const today = new Date();

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container pt-4'>
                {error && <Alert variant="danger">{error}</Alert>}
                <Card className="mb-3">
                    <Card.Body>
                        <h1>Datos Generales</h1>
                        <p className="card-text">Selecciona el grupo</p>
                        <Form.Select
                            name="grupo"
                            className="mb-3"
                            value={grupos.GrupoID}
                            onChange={(e) => {
                                setAlumnoData({
                                    ...alumnoData,
                                    GrupoID: e.target.value
                                });
                                handleGrupoChange(e);
                            }}
                        >
                            <option value="">Seleccione un grupo</option>
                            {grupos.filter(grupo => new Date(grupo.Fecha_Final) >= today).map(grupo => (
                                <option key={grupo.GrupoID} value={grupo.GrupoID}>{grupo.Numero_Salon + '-' + grupo.Oferta}</option>
                            ))}
                        </Form.Select>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Area</td>
                                    <td>Instructor</td>
                                    <td>Materia</td>
                                    <td>Oferta educativa</td>
                                    <td>Modalidad</td>
                                    <td>Salon</td>
                                    <td>Inicio de curso</td>
                                    <td>Frecuencia de clases</td>
                                    <td>Cantidad de clases</td>
                                </tr>
                            </thead>
                            <tbody>
                                {grupoSeleccionado ? (
                                    <tr>
                                        <td>{grupoSeleccionado.Area}</td>
                                        <td>{grupoSeleccionado.Maestro}</td>
                                        <td>{grupoSeleccionado.Modulo}</td>
                                        <td>{grupoSeleccionado.Oferta}</td>
                                        <td>{grupoSeleccionado.Modalidad}</td>
                                        <td>{grupoSeleccionado.Numero_Salon}</td>
                                        <td>{formatDate(grupoSeleccionado.Fecha_Inicial)}</td>
                                        <td>{grupoSeleccionado.Frecuencia}</td>
                                        <td>{calcularCantidadClases(grupoSeleccionado.Fecha_Inicial, grupoSeleccionado.Fecha_Final, grupoSeleccionado.Frecuencia)}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="9" className="text-center">No hay datos para mostrar</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <h1>Llenado de minuta</h1>
                        <p className="card-text">Selecciona el alumno</p>
                        <Form.Select
                            name="alumno"
                            className="mb-3"
                            onChange={(e) => {
                                setAlumnoData({
                                    ...alumnoData,
                                    AlumnoID: e.target.value
                                });
                                handleAlumnoChange(e);
                            }}
                        >
                            <option value="">Seleccione un alumno</option>
                            {alumnos.map(alumno => (
                                <option key={alumno.ID_Alumno} value={alumno.ID_Alumno}>
                                    {alumno.alumnoNombre}{" "}{alumno.Matricula}
                                </option>
                            ))}
                        </Form.Select>

                        {alumnoData.ID && alumnoData.CalificacionFinal && (
                            <Table striped bordered className="mt-3">
                                <thead>
                                    <tr>
                                        <th>Estado</th>
                                        <th>Calificación Final</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Completado</td>
                                        <td>{alumnoData.CalificacionFinal}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        )}

                        {mostrarCamposAlumno && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Seleccione el numero de clase a revisar</Form.Label>
                                    <Form.Select
                                        name="classnum"
                                        className="mb-3"
                                        value={alumnoData.claseSeleccionada || ''}
                                        onChange={handleClaseChange}
                                    >
                                        <option value="">Seleccione el número de clase</option>
                                        <option value="class1">Clase 1</option>
                                        <option value="class2">Clase 2</option>
                                        <option value="class3">Clase 3</option>
                                        <option value="class4">Clase 4</option>
                                    </Form.Select>
                                </Form.Group>

                                {renderCampoClaseSeleccionada()}

                                <Form.Group className="mb-3">
                                    <Form.Label>Calificacion del proyecto</Form.Label>
                                    <Form.Select
                                        name="proyecto"
                                        className="mb-3"
                                        value={alumnoData.Proyecto || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Proyecto: e.target.value
                                        })}
                                    >
                                        <option value="">Seleccione la calificacion del proyecto</option>
                                        <option value="E">E</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="N">N</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Calificación del examen</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="calificacion"
                                        placeholder="Ingresa la calificación"
                                        min="0"
                                        max="100"
                                        value={alumnoData.Examen || ''}
                                        onChange={(e) => {
                                            const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limita entre 0 y 100
                                            setAlumnoData({
                                                ...alumnoData,
                                                Examen: value
                                            });
                                        }}
                                    />
                                </Form.Group>


                                <Form.Group className="mb-3">
                                    <Form.Label>Participacion</Form.Label>
                                    <Form.Select
                                        name="participacion"
                                        className="mb-3"
                                        value={alumnoData.Participacion || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Participacion: e.target.value
                                        })}
                                    >
                                        <option value="">Seleccione la calificacion de la participacion</option>
                                        <option value="E">E</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="N">N</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Comentarios</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="comentarios"
                                        placeholder="Escribe algún comentario"
                                        value={alumnoData.Comentarios || ''}
                                        onChange={(e) => setAlumnoData({
                                            ...alumnoData,
                                            Comentarios: e.target.value
                                        })}
                                    />
                                </Form.Group>

                                <button className="btn btn-outline-success ml-2" onClick={handleGuardarCalificacion}>
                                    Guardar calificación
                                </button>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default Minutas_Card;
