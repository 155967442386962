import React from 'react';
import NavbarCard from '../components/navbar_card';
import EdiSalonesCard from '../components/edicionSalones';

const AlumnosAdd = () => {

    return (

        <div className="d-flex" style={{ width: '100%', height: '100vh' }}>
            <NavbarCard />
            <div className="flex-grow-1">
                <EdiSalonesCard />
            </div>
        </div>
    );
};

export default AlumnosAdd;